import { createSelector } from '@ngrx/store';
import { calculateTotalOrders } from 'src/app/shared/utils.functions';
import { IState } from '..';

export const selectState = (state: IState) => state.orders;

export const selectOrdersItems = createSelector(
  selectState,
  (state) => state?.ordered.items,
);

export const selectTotalOrdersCount = createSelector(selectState, (state) =>
  state?.ordered.tempCount
    ? state.ordered.tempCount
    : calculateTotalOrders(state.ordered.items),
);

export const selectIsTableDataLoadingOrders = createSelector(
  selectState,
  (state) => state?.ordered.loading,
);

export const selectFiredOrdersItems = createSelector(
  selectState,
  (state) => state?.fired.items,
);

export const selectTotalFiredOrdersCount = createSelector(
  selectState,
  (state) =>
    state.fired.tempCount
      ? state.fired.tempCount
      : calculateTotalOrders(state.fired.items),
);

export const selectIsTableDataLoadingFired = createSelector(
  selectState,
  (state) => state?.fired.loading,
);

export const selectDoneOrdersItems = createSelector(
  selectState,
  (state) => state?.done.items,
);

export const selectTotalDoneOrdersCount = createSelector(
  selectState,
  (state) =>
    state.done.tempCount
      ? state.done.tempCount
      : calculateTotalOrders(state.done.items),
);

export const selectIsTableDataLoadingDone = createSelector(
  selectState,
  (state) => state?.done.loading,
);
