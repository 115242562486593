import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'resto-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class HeaderComponent {
  @Input() immediateDone!: boolean | null;
  @Input() ordersCount!: number | null;
  @Input() reservationsCount!: number | null;
  @Input() firedOrdersCount!: number | null;
  @Input() doneOrdersCount!: number | null;
}
