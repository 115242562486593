<div class="wrapper">
  <h2 mat-dialog-title class="header">
    {{ 'shared.dialogs.description-dialog.title' | transloco }}
  </h2>
  <mat-dialog-content class="content">
    <mat-form-field class="textarea-field" subscriptSizing="dynamic">
      <mat-label>{{
        'shared.dialogs.description-dialog.field' | transloco
      }}</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMinRows="2"
        maxlength="2000"
        name="comment"
        type="text"
        class="popover-textarea"
        [formControl]="commentCtr"
      >
      </textarea>
    </mat-form-field>
  </mat-dialog-content>
</div>
<mat-dialog-actions class="actions">
  <button mat-button class="cancel" (click)="dialogRef.close()">
    {{ 'shared.buttons.cancel' | transloco }}
  </button>
  <button mat-button class="done" color="primary" (click)="submit()">
    {{ 'shared.buttons.save' | transloco }}
  </button>
</mat-dialog-actions>
