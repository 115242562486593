import { Injectable } from '@angular/core';
import { interval, mergeMap, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClockService {
  /**
   * Updates the clock every minute
   * @returns an observable that emits a new Date every minute
   */
  getClock = (): Observable<Date> =>
    interval(1000 * 60).pipe(mergeMap(() => of(new Date())));
}
