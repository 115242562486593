import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Consumer } from 'src/app/models/user';
import * as ReservationActions from './reservations.actions';

export function selectById(a: Consumer): string {
  const userKey = a.is_user ? 'Y' : 'N';
  return `${userKey}${a.id}`;
}

export const adapter: EntityAdapter<Consumer> = createEntityAdapter<Consumer>({
  selectId: selectById,
});

export interface ReservationsState {
  items: EntityState<Consumer>;
  count: number;
  next: string;
  previous: string;
  loading: boolean;
  rfid_detail: Consumer | null;
  rfid_details_loading: boolean;
  rfid_details_count: number | null;
}

export const initialState: ReservationsState = {
  items: adapter.getInitialState({}),
  count: 0,
  next: '',
  previous: '',
  loading: false,
  rfid_detail: null,
  rfid_details_loading: false,
  rfid_details_count: null,
};

const _reservationsReducer = createReducer(
  initialState,
  on(
    ReservationActions.setReservations,
    (state, { items: { count, next, previous, results } }) => {
      return {
        ...state,
        items: adapter.setAll(results, state?.items),
        count,
        next,
        previous,
      };
    },
  ),
  on(ReservationActions.setReservationsLoading, (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  }),

  on(ReservationActions.setRfidDetailsLoading, (state, { loading }) => {
    return {
      ...state,
      rfid_details_loading: loading,
    };
  }),
  on(ReservationActions.setRfidDetails, (state, { item }) => {
    return {
      ...state,
      rfid_detail: item,
    };
  }),
  on(ReservationActions.setRfidDetailsCount, (state, { count }) => {
    return {
      ...state,
      rfid_details_count: count,
    };
  }),

  on(ReservationActions.resetRfidDetails, (state) => {
    return {
      ...state,
      rfid_detail: null,
      rfid_details_count: null,
    };
  }),

  on(
    ReservationActions.updateReservationStatus,
    (state, { updatedConsumerDetail }) => {
      const reservationUpdatesUpdated = adapter
        .getSelectors()
        .selectAll(state.items)
        .filter((c) =>
          updatedConsumerDetail.find((el: any) => el.id === c.id)
            ? true
            : false,
        )
        .map((consumer) => ({
          id: consumer.is_user ? `Y${consumer.id}` : `N${consumer.id}`,
          changes: {
            ...consumer,
            service_status: true,
            service_status_table: updatedConsumerDetail.find(
              (el) => el.id === consumer.id,
            )!.table_number,
          },
        }));
      return {
        ...state,
        items: adapter.updateMany(reservationUpdatesUpdated, state?.items),
      };
    },
  ),
  on(
    ReservationActions.removeMultipleReservations,
    (state, { consumers, users }) => {
      const deletedIds = [
        ...consumers.map((id) => `N${id}`),
        ...users.map((id) => `Y${id}`),
      ];
      return {
        ...state,
        items: adapter.removeMany(deletedIds, state?.items),
        count: state.count - consumers.length,
      };
    },
  ),
  on(
    ReservationActions.clearMultipleReservations,
    (state, { consumers, users }) => {
      const reservationUpdates = adapter
        .getSelectors()
        .selectAll(state.items)
        .filter(
          (c) =>
            (c.is_user === false && consumers.includes(c.id)) ||
            (c.is_user === true && users.includes(c.id)),
        )
        .map((consumer) => ({
          id: consumer.is_user ? `Y${consumer.id}` : `N${consumer.id}`,
          changes: {
            ...consumer,
            service_status: false,
            service_status_table: '',
          },
        }));
      return {
        ...state,
        items: adapter.updateMany(reservationUpdates, state?.items),
      };
    },
  ),
  on(ReservationActions.changeReservation, (state, { consumer }) => {
    return {
      ...state,
      items: adapter.updateOne(
        {
          id: consumer.is_user ? `Y${consumer.id}` : `N${consumer.id}`,
          changes: consumer,
        },
        state?.items,
      ),
    };
  }),
  on(ReservationActions.clearAllReservations, () => {
    return { ...initialState };
  }),
);

export function reservationsReducer(
  state: ReservationsState | undefined,
  action: Action,
) {
  return _reservationsReducer(state, action);
}
