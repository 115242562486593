import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import {
  catchError,
  concatMap,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  AddOrderParams,
  GroupedTickets,
  Order,
  ServiceStatus,
  UpdateOrderParams,
} from 'src/app/models/orders';
import { ReservationDetail } from 'src/app/models/reservations';
import { WebsocketAction } from 'src/app/models/websocket';
import { ConfigService } from 'src/app/services/config/config.service';
import { GenericsService } from 'src/app/services/generics/generics.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import {
  getFormattedDate,
  getTimeBasedNumber,
} from 'src/app/shared/utils.functions';

import {
  clearMultipleReservations,
  removeMultipleReservations,
  updateReservationStatus,
} from '../reservations/reservations.actions';
import { handleHttpError } from '../shared/shared.actions';
import { selectSectionFilter } from '../shared/shared.selectors';
import {
  selectCurrentDate,
  selectCurrentService,
  selectTablesToShow,
  selectUserLocation,
} from '../user/user.selectors';
import { IState } from '..';
import * as OrdersActions from './orders.actions';

@Injectable()
export class OrdersEffects {
  fetchOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.fetchOrders),
      tap(({ params: { service_status } }) => {
        this.ngrxStore.dispatch(
          OrdersActions.setOrdersLoading({
            loading: true,
            service_status: service_status,
          }),
        );
      }),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectUserLocation),
        this.ngrxStore.select(selectSectionFilter),
      ),
      mergeMap(
        ([{ params }, currentService, date, location, sectionFilter]) => {
          const extraParams: {
            service_status?: number;
            date?: string;
            section_level2_baselang?: string;
            location?: number;
            section_level3_baselang?: string;
          } = {};
          if (currentService)
            extraParams['section_level2_baselang'] = currentService;
          if (location) extraParams['location'] = location;
          if (sectionFilter)
            extraParams['section_level3_baselang'] = sectionFilter;
          extraParams['date'] = date ? date : getFormattedDate();
          return this.genericService
            .get<GroupedTickets[]>(this.configService.groupedOrders, {
              ...extraParams,
              ...params,
            })
            .pipe(
              mergeMap((res: GroupedTickets[]) => [
                OrdersActions.setOrders({
                  items: res,
                  service_status: params.service_status,
                }),
                OrdersActions.setOrdersLoading({
                  loading: false,
                  service_status: params.service_status,
                }),
              ]),
              catchError((error: unknown) => [
                handleHttpError({ error: error as HttpErrorResponse }),
                OrdersActions.setOrdersLoading({
                  loading: false,
                  service_status: params.service_status,
                }),
              ]),
            );
        },
      ),
    ),
  );

  updateOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.updateOrderStatus),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      mergeMap(
        ([{ ids, previous_status, new_status }, currentService, currentDate]) =>
          this.genericService
            .patch<UpdateOrderParams, Order[]>(
              this.configService.updateOrderEP,
              {
                ids,
                data: { service_status: new_status },
                date: currentDate ?? getFormattedDate(),
                service: currentService ?? '',
                return_orders: true,
              },
            )
            .pipe(
              tap((items) => {
                this.websockets.sendMessage({
                  date: currentDate ?? getFormattedDate(),
                  service: currentService ?? '',
                  data: {
                    ids,
                    previous_status,
                    new_status,
                    items,
                  },
                  action: WebsocketAction.UPDATE_STATUS,
                });
              }),
              mergeMap((items) => [
                OrdersActions.changeOrderStatus({
                  ids,
                  previous_status,
                  new_status,
                  items,
                }),
              ]),
              catchError((error: unknown) => [
                handleHttpError({ error: error as HttpErrorResponse }),
              ]),
            ),
      ),
    ),
  );

  deleteOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.deleteOrders),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectCurrentService),
      ),
      mergeMap(
        ([
          { ids, service_status, count, section },
          currentDate,
          currentService,
        ]) =>
          this.genericService
            .post<Partial<UpdateOrderParams>, Order[]>(
              this.configService.deleteOrderEP,
              {
                ids,
                hide_delete: true,
              },
            )
            .pipe(
              tap(() => {
                this.websockets.sendMessage({
                  date: currentDate ?? getFormattedDate(),
                  service: currentService ?? '',
                  data: {
                    ids,
                    service_status,
                    count,
                    section,
                  },
                  action: WebsocketAction.DELETE,
                });
              }),
              mergeMap(() => [
                OrdersActions.removeOrders({ ids, service_status }),
              ]),
              catchError((error: unknown) => [
                handleHttpError({ error: error as HttpErrorResponse }),
              ]),
            ),
      ),
    ),
  );

  addSpecialOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.addSpecialOrder),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      mergeMap(([{ data }, currentService, date]) => {
        const payload = Object.assign(
          {
            date: date ?? getFormattedDate(),
            section_level2: currentService,
            section_level2_baselang: currentService,
            item_option: getTimeBasedNumber(10, 32767),
          },
          data,
        );
        return this.genericService
          .post<
            Partial<AddOrderParams>,
            Order
          >(this.configService.orders, payload, { tickets: true })
          .pipe(
            tap((order) => {
              this.websockets.sendMessage({
                date: date ?? getFormattedDate(),
                service: currentService ?? '',
                data: [order],
                action: WebsocketAction.CREATE,
              });
            }),
            mergeMap((order) => [
              OrdersActions.setNewSpecialOrder({
                order,
                service_status: data.service_status,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  changeOrderTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.changeOrderTable),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      switchMap(([{ data, service_status }, currentService, date]) => {
        const currentDate: string = date ? date : getFormattedDate();
        const ordersData = Object.assign(
          {
            date: currentDate,
            service: currentService ?? '',
            return_orders: true,
          },
          data,
        );
        return this.genericService
          .post<
            Partial<ReservationDetail>,
            Order[]
          >(this.configService.updateConsumerService, ordersData, { return_orders: true })
          .pipe(
            tap((orders) => {
              this.websockets.sendMessage({
                date: currentDate,
                service: currentService ?? '',
                data: {
                  ids: orders.flatMap((ord) => ord.ids),
                  items: orders,
                },
                action: WebsocketAction.UPDATE,
              });
            }),
            mergeMap(() => [
              OrdersActions.fetchOrders({ params: { service_status } }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  processWebsocketsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.processWebsocketsData),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectTablesToShow),
        this.ngrxStore.select(selectSectionFilter),
      ),
      concatMap(
        ([
          { message },
          currentService,
          currentDate,
          activeTables,
          sectionFilter,
        ]) => {
          if (
            message.date !== (currentDate ?? getFormattedDate()) ||
            message.service !== currentService
          )
            return [];
          const actions: Action<string>[] = [];
          if (message.action === WebsocketAction.UPDATE_STATUS) {
            const data = message.data as {
              ids: number[];
              previous_status: ServiceStatus;
              new_status: ServiceStatus;
              items: Order[];
            };
            if (data.new_status === 1) {
              actions.push(
                OrdersActions.removeOrders({
                  ids: data.ids,
                  service_status: data.previous_status,
                }),
              );
            } else {
              if (activeTables?.length) {
                data['items'] = data['items'].filter((order) =>
                  activeTables.includes(order.table_number),
                );
              }
              if (sectionFilter?.length) {
                data['items'] = data['items'].filter((order) =>
                  sectionFilter.includes(order.section),
                );
              }
              actions.push(OrdersActions.changeOrderStatus(data));
            }
          } else if (message.action === WebsocketAction.DELETE) {
            const data = message.data as {
              ids: number[];
              service_status: ServiceStatus;
              count: number;
            };
            actions.push(OrdersActions.removeOrders(data));
          } else if (message.action === WebsocketAction.RESET) {
            const data = message.data as {
              ids: number[];
              items: Order[];
              consumers: number[];
              users: number[];
            };
            actions.push(
              clearMultipleReservations({
                consumers: data.consumers,
                users: data.users,
              }),
            );
            for (const d of data.items) {
              actions.push(
                OrdersActions.removeOrders({
                  ids: d.ids,
                  service_status: d.service_status,
                }),
              );
            }
          } else if (message.action === WebsocketAction.RESET_DELETE) {
            const data = message.data as {
              ids: number[];
              items: Order[];
              consumers: number[];
              users: number[];
            };
            actions.push(
              removeMultipleReservations({
                consumers: data.consumers,
                users: data.users,
              }),
            );
            for (const d of data.items) {
              actions.push(
                OrdersActions.removeOrders({
                  ids: d.ids,
                  service_status: d.service_status,
                }),
              );
            }
          } else if (message.action === WebsocketAction.UPDPATE_RESERVATION) {
            const data = message.data as {
              consumers: number[];
              users: number[];
              table_number: string;
            };
            const reservationsToUpdate: {
              id: number;
              is_user: boolean;
              table_number: string;
            }[] = [];
            data.consumers.forEach((id: number) => {
              reservationsToUpdate.push({
                id,
                is_user: false,
                table_number: data.table_number,
              });
            });
            data.users.forEach((id: number) => {
              reservationsToUpdate.push({
                id,
                is_user: false,
                table_number: message.data.table_number,
              });
            });
            actions.push(
              updateReservationStatus({
                updatedConsumerDetail: reservationsToUpdate,
              }),
            );
          }
          return actions;
        },
      ),
    ),
  );

  expediteOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.expediteOrder),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      mergeMap(
        ([{ ids, selectedReason, value }, currentService, currentDate]) =>
          this.genericService
            .patch<UpdateOrderParams, Order[]>(
              this.configService.updateOrderEP,
              {
                ids,
                data: {
                  service_expedite: value,
                  service_expedite_reason: selectedReason,
                },
                date: currentDate ?? getFormattedDate(),
                service: currentService ?? '',
                return_orders: true,
              },
            )
            .pipe(
              tap((items) => {
                this.websockets.sendMessage({
                  date: currentDate ?? getFormattedDate(),
                  service: currentService ?? '',
                  data: {
                    ids,
                    items,
                  },
                  action: WebsocketAction.UPDATE,
                });
              }),
              mergeMap((items) => [
                OrdersActions.changeOrders({ items, service_status: 3 }),
              ]),
              catchError((error: unknown) => [
                handleHttpError({ error: error as HttpErrorResponse }),
              ]),
            ),
      ),
    ),
  );

  updateOrderComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.updateOrderComment),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      mergeMap(([{ id, comment }, currentService, currentDate]) =>
        this.genericService
          .patch<UpdateOrderParams, Order[]>(this.configService.updateOrderEP, {
            ids: [id],
            data: {
              description: comment as any,
            },
            date: currentDate ?? getFormattedDate(),
            service: currentService ?? '',
            return_orders: true,
          })
          .pipe(
            tap((items) => {
              this.websockets.sendMessage({
                date: currentDate ?? getFormattedDate(),
                service: currentService ?? '',
                data: {
                  ids: [id],
                  items,
                },
                action: WebsocketAction.UPDATE,
              });
            }),
            mergeMap((items) => [
              OrdersActions.changeOrders({
                items,
                service_status: items[0].service_status,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private genericService: GenericsService,
    private ngrxStore: Store<IState>,
    private websockets: WebsocketService,
  ) {}
}
