import { createSelector } from '@ngrx/store';
import { adapter } from './reservations.reducer';
import { IState } from '..';

export const selectState = (state: IState) => state.reservations;

export const { selectAll } = adapter.getSelectors();

const selectConsumerItems = createSelector(
  selectState,
  (state) => state?.items,
);

export const selectConsumersEntities = createSelector(
  selectConsumerItems,
  selectAll,
);

export const selectTotalReservationsCount = createSelector(
  selectState,
  (state) => state?.count,
);

export const selectIsTableDataLoading = createSelector(
  selectState,
  (state) => state?.loading,
);

export const selectIsRfidDetailsLoading = createSelector(
  selectState,
  (state) => state?.rfid_details_loading,
);

export const selectRfidDetails = createSelector(
  selectState,
  (state) => state?.rfid_detail,
);

export const selectRfidDetailsCount = createSelector(
  selectState,
  (state) => state?.rfid_details_count,
);
