<form>
  <mat-form-field
    class="chip-list"
    [class.full-width]="fullWidth"
    appearance="fill"
    subscriptSizing="dynamic"
  >
    <mat-label>{{
      'settings.limit-tables-displayed.tables' | transloco
    }}</mat-label>
    <mat-chip-grid #chipGrid aria-label="table selection">
      @for (tag of selectedTags; track tag) {
        <mat-chip-row (removed)="remove(tag)">
          {{ tag }}
          <button matChipRemove [attr.aria-label]="'remove ' + tag">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        #tagInput
        [formControl]="tagCtrl"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [placeholder]="
          'settings.limit-tables-displayed.tables-placeholder' | transloco
        "
        (matChipInputTokenEnd)="add($event)"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
      >
        @for (tag of filteredTags | async; track tag) {
          <mat-option [value]="tag">
            {{ tag }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-chip-grid>
  </mat-form-field>
</form>
