import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { catchError, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs';
import { ConfigService } from 'src/app/services/config/config.service';
import {
  DeletedServiceStatus,
  ReservationDetail,
} from 'src/app/models/reservations';
import { fetchOrders, removeOrders } from '../orders/orders.actions';
import { GenericsService } from 'src/app/services/generics/generics.service';
import { getFormattedDate } from 'src/app/shared/utils.functions';
import { handleHttpError } from '../shared/shared.actions';
import { Results } from 'src/app/models/generics';
import { Order } from 'src/app/models/orders';
import { Consumer } from 'src/app/models/user';
import {
  selectCurrentDate,
  selectCurrentService,
  selectImmedateDone,
} from '../user/user.selectors';
import { IState } from 'src/app/reducers';
import { WebsocketAction } from 'src/app/models/websocket';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import * as ReservationActions from './reservations.actions';

@Injectable()
export class ReservationsEffects {
  fetchConsumers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationActions.fetchConsumers),
      tap(() =>
        this.ngrxStore.dispatch(
          ReservationActions.setReservationsLoading({ loading: true }),
        ),
      ),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      switchMap(([{ params }, currentService, date]) => {
        const extraParams: {
          has_orders_date?: string;
          has_orders_service?: string;
          highlight_service_status: boolean;
        } = {
          highlight_service_status: true,
        };
        if (currentService) extraParams['has_orders_service'] = currentService;
        extraParams['has_orders_date'] = date ? date : getFormattedDate();
        return this.genericService
          .get<Results<Consumer>>(this.configService.consumers, {
            ...extraParams,
            ...params,
          })
          .pipe(
            mergeMap((res: Results<Consumer>) => [
              ReservationActions.setReservations({ items: res }),
              ReservationActions.setReservationsLoading({
                loading: false,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
              ReservationActions.setReservationsLoading({ loading: false }),
            ]),
          );
      }),
    ),
  );

  fetchRfidDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationActions.fetchRfidDetails),
      tap(() =>
        this.ngrxStore.dispatch(
          ReservationActions.setRfidDetailsLoading({ loading: true }),
        ),
      ),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      switchMap(([{ rfid }, currentService, date]) => {
        const extraParams: {
          has_orders_date?: string;
          has_orders_service?: string;
          highlight_service_status: boolean;
          rfid: string;
        } = {
          highlight_service_status: true,
          rfid,
        };
        if (currentService) extraParams['has_orders_service'] = currentService;
        extraParams['has_orders_date'] = date ? date : getFormattedDate();
        return this.genericService
          .get<Results<Consumer>>(this.configService.consumers, {
            ...extraParams,
          })
          .pipe(
            mergeMap((res: Results<Consumer>) => [
              ReservationActions.setRfidDetailsCount({ count: res.count }),
              ReservationActions.setRfidDetails({
                item: res.results[0] ? res.results[0] : null,
              }),
              ReservationActions.setRfidDetailsLoading({
                loading: false,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
              ReservationActions.setRfidDetailsLoading({ loading: false }),
            ]),
          );
      }),
    ),
  );

  changeReservationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationActions.changeReservationStatus),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectImmedateDone),
      ),
      mergeMap(
        ([{ data, change, callback }, currentService, date, immediateDone]) => {
          const currentDate = date ? date : getFormattedDate();
          const reservationData = Object.assign(
            {
              date: currentDate,
              service: currentService ?? '',
              return_orders: true,
            },
            data,
          );
          return this.genericService
            .post<
              Partial<ReservationDetail>,
              Order[]
            >(this.configService.updateConsumerService, reservationData)
            .pipe(
              tap((orders) => {
                this.websockets.sendMessage({
                  date: currentDate,
                  service: currentService ?? '',
                  data: {
                    consumers: data.consumers ?? [],
                    users: data.users ?? [],
                    table_number: data.table_number ?? '',
                  },
                  action: WebsocketAction.UPDPATE_RESERVATION,
                });
                // group orders by service_status
                const groupedOrders = orders.reduce(
                  (acc, curr) => {
                    const key = curr.service_status;
                    if (!acc[key]) {
                      acc[key] = [];
                    }
                    acc[key].push(curr);
                    return acc;
                  },
                  {} as { [key: number]: Order[] },
                );
                // send websocket message for each service_status
                Object.keys(groupedOrders).forEach((key) => {
                  this.websockets.sendMessage({
                    date: currentDate,
                    service: currentService ?? '',
                    data: {
                      service_status: change ? key : 1,
                      ids: orders.flatMap((ord) => ord.ids),
                      items: orders,
                    },
                    action: WebsocketAction.UPDATE,
                  });
                });
                if (!change) {
                  this.websockets.sendMessage({
                    date: currentDate,
                    service: currentService ?? '',
                    data: {
                      ids: orders.flatMap((ord) => ord.ids),
                      previous_status: 1,
                      new_status: immediateDone ? 4 : 2,
                      items: orders,
                    },
                    action: WebsocketAction.UPDATE_STATUS,
                  });
                }
              }),
              mergeMap((orders) => {
                const updatedConsumerDetail = orders.map((ord) => ({
                  id: ord.is_user ? ord.created_by : ord.consumer,
                  is_user: ord.is_user,
                  table_number: ord.service_table_number,
                }));
                callback?.();
                return [
                  fetchOrders({
                    params: { service_status: immediateDone ? 4 : 2 },
                  }),
                  ReservationActions.updateReservationStatus({
                    updatedConsumerDetail,
                  }),
                ];
              }),
              catchError((error: unknown) => [
                handleHttpError({ error: error as HttpErrorResponse }),
              ]),
            );
        },
      ),
    ),
  );

  deleteReservationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationActions.deleteReservations),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      switchMap(([{ data }, currentService, date]) => {
        const reservationData = Object.assign(
          {
            date: date ? date : getFormattedDate(),
            service: currentService ?? '',
            return_orders: true,
            delete_orders: true,
          },
          data,
        );
        return this.genericService
          .post<
            Partial<ReservationDetail>,
            DeletedServiceStatus[]
          >(this.configService.deleteConsumerService, reservationData)
          .pipe(
            tap((res) => {
              this.websockets.sendMessage({
                date: date ? date : getFormattedDate(),
                service: currentService ?? '',
                data: res,
                action: WebsocketAction.RESET_DELETE,
              });
            }),
            mergeMap((res) => {
              const deletableTickets: {
                orders: number[];
                fired: number[];
                done: number[];
              } = res.reduce(
                (result: any, item: DeletedServiceStatus) => {
                  if (item.service_status === 2) {
                    return {
                      ...result,
                      orders: [...result.orders, ...item.order_ids],
                    };
                  } else if (item.service_status === 3) {
                    return {
                      ...result,
                      fired: [...result.fired, ...item.order_ids],
                    };
                  } else if (item.service_status === 4) {
                    return {
                      ...result,
                      done: [...result.done, ...item.order_ids],
                    };
                  }
                },
                {
                  orders: [] as number[],
                  fired: [] as number[],
                  done: [] as number[],
                },
              );
              const actions: Action<string>[] = [];
              if (deletableTickets.orders.length) {
                actions.push(
                  removeOrders({
                    ids: deletableTickets.orders,
                    service_status: 2,
                  }),
                );
              }
              if (deletableTickets.fired.length) {
                actions.push(
                  removeOrders({
                    ids: deletableTickets.fired,
                    service_status: 3,
                  }),
                );
              }
              if (deletableTickets.done.length) {
                actions.push(
                  removeOrders({
                    ids: deletableTickets.done,
                    service_status: 4,
                  }),
                );
              }
              return [
                ...actions,
                ReservationActions.removeMultipleReservations({
                  users: data.users ?? [],
                  consumers: data.consumers ?? [],
                }),
              ];
            }),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  clearReservationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationActions.resetReservations),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      switchMap(([{ data }, currentService, date]) => {
        const reservationData = Object.assign(
          {
            date: date ? date : getFormattedDate(),
            service: currentService ?? '',
            return_orders: true,
          },
          data,
        );
        return this.genericService
          .post<
            Partial<ReservationDetail>,
            Order[]
          >(this.configService.deleteConsumerService, reservationData)
          .pipe(
            mergeMap((res) => {
              const deletableTickets: {
                orders: number[];
                fired: number[];
                done: number[];
              } = res.reduce(
                (result: any, item: Order) => {
                  if (item.service_status === 2) {
                    return {
                      ...result,
                      orders: [...result.orders, ...item.ids],
                    };
                  } else if (item.service_status === 3) {
                    return {
                      ...result,
                      fired: [...result.fired, ...item.ids],
                    };
                  } else if (item.service_status === 4) {
                    return {
                      ...result,
                      done: [...result.done, ...item.ids],
                    };
                  }
                  return result;
                },
                {
                  orders: [] as number[],
                  fired: [] as number[],
                  done: [] as number[],
                },
              );
              const actions: Action<string>[] = [];
              if (deletableTickets.orders.length) {
                actions.push(
                  removeOrders({
                    ids: deletableTickets.orders,
                    service_status: 2,
                  }),
                );
              }
              if (deletableTickets.fired.length) {
                actions.push(
                  removeOrders({
                    ids: deletableTickets.fired,
                    service_status: 3,
                  }),
                );
              }
              if (deletableTickets.done.length) {
                actions.push(
                  removeOrders({
                    ids: deletableTickets.done,
                    service_status: 4,
                  }),
                );
              }
              this.websockets.sendMessage({
                date: date ? date : getFormattedDate(),
                service: currentService ?? '',
                data: {
                  ids: res.flatMap((item) => item.ids),
                  items: res,
                  consumers: data.consumers ?? [],
                  users: data.users ?? [],
                },
                action: WebsocketAction.RESET,
              });
              return [
                ...actions,
                ReservationActions.clearMultipleReservations({
                  users: data.users ?? [],
                  consumers: data.consumers ?? [],
                }),
              ];
            }),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  updateConsumer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationActions.updateConsumer),
      mergeMap(({ data, url }) =>
        this.genericService.patch<Partial<Consumer>, Consumer>(url, data).pipe(
          tap(() => {
            // FIXME: send websocket message
          }),
          mergeMap((consumer) => [
            ReservationActions.changeReservation({ consumer }),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private genericService: GenericsService,
    private ngrxStore: Store<IState>,
    private websockets: WebsocketService,
  ) {}
}
