import * as fromRouter from '@ngrx/router-store';

import { IState } from '.';

export const selectRouter = (state: IState) => state.router;

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = fromRouter.getRouterSelectors(selectRouter);

export const selectServiceParam = selectQueryParam('service');
