<resto-done-orders-table
  [doneOrders]="doneOrders$ | async"
  [isRequireTableSeating]="isRequireTableSeating$ | async"
  [itemsCount]="totalDoneOrdersCount$ | async"
  [loadingSpinner]="isTableDataLoading$ | async"
  [selectedColumns]="selectedColumns$ | async"
  [sectionFilter]="sectionFilter$ | async"
  [sections]="availableSections$ | async"
  [tablesToShow]="tablesToShow$ | async"
  (applyParams)="retrieveOrders($event)"
  (deleteOrder)="deleteOrder($event)"
  (filterBySection)="filterBySection($event)"
  (undoOrder)="undoOrder($event)"
  (updateComment)="updateComment($event)"
></resto-done-orders-table>
