<mat-form-field class="datefield" appearance="fill" subscriptSizing="dynamic">
  <input
    matInput
    readonly
    [matDatepicker]="picker"
    (click)="picker.open()"
    [formControl]="date"
    (dateChange)="dateChange($event)"
  />
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon class="date-arrow" matDatepickerToggleIcon
      >keyboard_arrow_down</mat-icon
    >
  </mat-datepicker-toggle>
</mat-form-field>
<mat-datepicker #picker></mat-datepicker>

@if (services.length) {
  <mat-form-field class="services" appearance="fill" subscriptSizing="dynamic">
    <mat-select [formControl]="selectFormControl">
      @for (option of services; track option) {
        <mat-option
          (onSelectionChange)="selectService($event)"
          [value]="option"
          >{{ option }}</mat-option
        >
      }
    </mat-select>
  </mat-form-field>
}
