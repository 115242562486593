import { createAction, props } from '@ngrx/store';
import { ConsumerParams, ReservationDetail } from 'src/app/models/reservations';
import { Results } from 'src/app/models/generics';
import { Consumer } from 'src/app/models/user';

export const fetchConsumers = createAction(
  `[Reservations] Fetch consumers`,
  props<{
    currentService?: string;
    date?: string;
    params?: Partial<ConsumerParams>;
  }>(),
);

export const updateConsumer = createAction(
  `[Reservations] Update consumer`,
  props<{ data: Partial<Consumer>; url: string }>(),
);

export const changeReservation = createAction(
  `[Reservations] Change reservation locally`,
  props<{ consumer: Consumer }>(),
);

export const fetchRfidDetails = createAction(
  `[Reservations] Fetch rfid details`,
  props<{ rfid: string }>(),
);

export const setRfidDetailsLoading = createAction(
  `[Reservations] Set rfid details loading`,
  props<{ loading: boolean }>(),
);

export const setReservationsLoading = createAction(
  `[Reservations] Set reservations loading`,
  props<{ loading: boolean }>(),
);

export const setRfidDetails = createAction(
  `[Reservations] Set rfid details locally`,
  props<{ item: Consumer | null }>(),
);

export const setRfidDetailsCount = createAction(
  `[Reservations] Set rfid details count locally`,
  props<{ count: number }>(),
);

export const resetRfidDetails = createAction(
  `[Reservations] Reset rfid details locally`,
);

export const setReservations = createAction(
  `[Reservations] Set reservations locally`,
  props<{ items: Results<Consumer> }>(),
);

export const changeReservationStatus = createAction(
  `[Reservations] Change reservation status`,
  props<{
    data: Partial<ReservationDetail>;
    change: boolean;
    callback?: () => void;
  }>(),
);

export const updateReservationStatus = createAction(
  `[Reservations] Update reservation status locally`,
  props<{
    updatedConsumerDetail: {
      id: number;
      is_user: boolean;
      table_number: string;
    }[];
  }>(),
);

export const deleteReservations = createAction(
  `[Reservations] Delete reservation status`,
  props<{ data: Partial<ReservationDetail> }>(),
);

export const resetReservations = createAction(
  `[Reservations] Remove reservation status`,
  props<{ data: Partial<ReservationDetail> }>(),
);

export const clearMultipleReservations = createAction(
  `[Reservations] Clear reservations locally`,
  props<{ consumers: number[]; users: number[] }>(),
);

export const removeMultipleReservations = createAction(
  `[Reservations] Remove reservations locally`,
  props<{ consumers: number[]; users: number[] }>(),
);

export const clearAllReservations = createAction(
  `[Reservations] Clear all reservations locally`,
);
