import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { isNil, omitBy } from 'lodash-es';
import { ContentLanguage } from 'src/app/models/languages';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { reverseSorting } from 'src/app/models/generics';

@Component({
  selector: 'resto-table-container',
  template: ``,
})
export class TableBaseComponent implements AfterViewInit, OnChanges {
  @Input() currentPageIndex!: number;
  @Input() deletion!: boolean;
  @Input() items!: any[];
  @Input() itemsCount!: number | null;
  @Input() lang!: ContentLanguage;
  @Input() loadingSpinner: boolean | null = false;
  @Input() showSpinner = false;
  @Output() applyParams = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() resetSpinner = new EventEmitter();
  @Output() selectedRow = new EventEmitter<{
    row: any;
    delete?: boolean;
    panel?: string;
  }>();
  @Output() showAddBtn = new EventEmitter();

  filterParam!: string;
  filterValue!: boolean;
  ordering!: string;
  pageSize = 20;
  path = '';
  search = '';
  storage_area = '';
  searchControl = new FormControl('');
  selectedRowId!: number;

  searchStorageAreaControl = new FormControl('');

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((v) => this.handleSearch(v as string));
    this.path = this.router.url.split('?')[0];
    this.route.queryParamMap
      .pipe(take(1))
      .subscribe((params: ParamMap) => this.handleSearchParams(params));

    this.path = this.router.url.split('?')[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['items'] && this.showSpinner) {
      this.selectRow(this.items[0]);
      this.resetSpinner.emit();
    }
  }

  clearSearch(): void {
    this.searchControl.reset();
  }

  clearStorageAreaSearch(): void {
    this.searchStorageAreaControl.reset();
  }

  handleSearch(value: string): void {
    if (value && value.length >= 3) {
      this.search = value;
      this.applyParams.emit(this.getParams());
    } else if (!value) {
      this.search = '';
      this.applyParams.emit(this.getParams());
    }
    const queryParams = omitBy({ search: this.search }, isNil);
    this.router.navigate([this.path], { queryParams });
  }

  handleSearchParams(params: ParamMap): void {
    if (params.has('search')) {
      setTimeout(() => this.searchControl.patchValue(params.get('search')), 0);
    }
  }

  deleteItem(item: any, event: Event): void {
    this.delete.emit(item);
    this.selectRow(item, event, true);
  }

  editItem(item: any, event: Event): void {
    if (item.id === this.selectedRowId) {
      event.stopPropagation();
      return undefined;
    }
    this.selectRow(item, event);
  }

  filter(criteria: string, value: boolean) {
    this.filterParam = criteria;
    this.filterValue = value;
    this.applyParams.emit(this.getParams());
  }

  getParams(): any {
    const params = {
      ordering: this.ordering,
      page_size: this.pageSize,
      search: this.search,
      [this.filterParam]: this.filterValue,
    };
    return omitBy(params, isNil);
  }

  handlePaginator({
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  }) {
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
      this.applyParams.emit(this.getParams());
    } else if (pageIndex !== this.currentPageIndex) {
      this.applyParams.emit({ ...this.getParams(), page: pageIndex + 1 });
    }
  }

  selectRow(row: any, event?: Event, del = false, panel?: string): void {
    if (event) event.stopPropagation();
    this.selectedRowId = row ? row.id : null;
    this.selectedRow.emit({ row, delete: del, panel });
  }

  sort(criteria: string | undefined) {
    this.ordering = criteria || reverseSorting(this.ordering);
    this.applyParams.emit(this.getParams());
  }
}
