<button
  mat-tab-link
  class="nav-button"
  mat-raised-button
  [routerLink]="'reservations'"
  routerLinkActive="active"
  queryParamsHandling="preserve"
>
  <span class="label">
    <mat-icon class="send">send</mat-icon>
    {{ 'shared.top-navbar.reservations' | transloco }}
  </span>
  <span class="count">&nbsp;{{ reservationsCount }}</span>
</button>

@if (!immediateDone) {
  <button
    mat-tab-link
    class="nav-button"
    mat-raised-button
    [routerLink]="'orders'"
    routerLinkActive="active"
    queryParamsHandling="preserve"
  >
    <span class="label">
      <mat-icon class="table">table_bar</mat-icon>
      {{ 'shared.top-navbar.orders' | transloco }}
    </span>
    <span class="count">&nbsp;{{ ordersCount }}</span>
  </button>
}

@if (!immediateDone) {
  <button
    mat-tab-link
    class="nav-button"
    mat-raised-button
    [routerLink]="'fired'"
    routerLinkActive="active"
    queryParamsHandling="preserve"
  >
    <span class="label">
      <mat-icon class="bolt">bolt</mat-icon>
      {{ 'shared.top-navbar.fired' | transloco }}
    </span>
    <span class="count">&nbsp;{{ firedOrdersCount }}</span>
  </button>
}
<button
  mat-tab-link
  class="nav-button"
  mat-raised-button
  [routerLink]="'done'"
  routerLinkActive="active"
  queryParamsHandling="preserve"
>
  <span class="label">
    <mat-icon class="done">done</mat-icon>
    {{ 'shared.top-navbar.done' | transloco }}
  </span>
  <span class="count">&nbsp;{{ doneOrdersCount }}</span>
</button>
