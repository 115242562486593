import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'resto-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
})
export class SortButtonComponent {
  @Input() currentKey!: string | null;
  @Input() key!: string;
  @Output() changeSorting = new EventEmitter<string | undefined>();
}
