import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { compact } from 'lodash-es';
import {
  catchError,
  EMPTY,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ConfigService } from 'src/app/services/config/config.service';
import {
  clearAllReservations,
  fetchConsumers,
} from '../reservations/reservations.actions';
import {
  clearAllOrders,
  clearOrders,
  fetchOrders,
  processWebsocketsData,
} from '../orders/orders.actions';
import { GenericsService } from 'src/app/services/generics/generics.service';
import { getFormattedDate } from 'src/app/shared/utils.functions';
import {
  handleHttpError,
  setGlobalError,
  showSnackbarMessage,
} from '../shared/shared.actions';
import { LoginForm } from 'src/app/models/authentication';
import { LoginSuccess, ServiceSettings, User } from 'src/app/models/user';
import { selectServiceParam } from '../router.selectors';
import {
  selectCurrentDate,
  selectUser,
  selectUserLocation,
} from './user.selectors';
import { IState } from 'src/app/reducers';
import { UserService } from 'src/app/services/user/user.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import * as UserAction from './user.actions';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable()
export class UserEffects {
  afterEffectsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() => {
        this.matIconRegistry.setDefaultFontSetClass(
          'material-symbols-outlined',
        );
        const token = this.userService.getToken();
        return [
          UserAction.setLogin({ isLogged: !!token }),
          UserAction.restoreUserLocally(),
          ...(token
            ? [UserAction.fetchUserDetail(), UserAction.fetchTables()]
            : []),
        ];
      }),
      catchError(() => EMPTY),
    ),
  );

  restoreFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.restoreUserLocally),
      switchMap(() => {
        try {
          const user = this.userService.getStoredUser();
          return [UserAction.setUser({ payload: user })];
        } catch {
          return EMPTY;
        }
      }),
      catchError(() => EMPTY),
    ),
  );

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.loginUser),
      tap(() => {
        this.ngrxStore.dispatch(UserAction.setLoginSpinner({ loading: true }));
      }),
      switchMap(({ email, password }) =>
        this.genericService
          .post<LoginForm, LoginSuccess>(this.configService.login, {
            email,
            password,
            service: true,
          })
          .pipe(
            mergeMap((successData) => {
              this.userService.saveToken(successData);
              this.router.navigate(['reservations']);
              return [
                UserAction.fetchUserDetail(),
                UserAction.setLogin({ isLogged: true }),
                UserAction.setLoginSpinner({ loading: false }),
                UserAction.fetchTables(),
              ];
            }),
            catchError((error: unknown) => [
              UserAction.setLoginSpinner({ loading: false }),
              handleHttpError({
                error: error as HttpErrorResponse,
                formId: 'loginForm',
              }),
            ]),
          ),
      ),
    ),
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.logoutUser),
      tap(() => {
        this.userService.removeToken();
      }),
      switchMap(() => [
        clearAllOrders(),
        clearAllReservations(),
        UserAction.setLogin({ isLogged: false }),
        UserAction.removeUserLocally(),
        UserAction.disconnectWebSocket(),
        UserAction.redirectAfterLoggingOut(),
      ]),
      catchError((error: unknown) => [
        handleHttpError({ error: error as HttpErrorResponse }),
      ]),
    ),
  );

  redirectAfterLoggingOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAction.redirectAfterLoggingOut),
        switchMap(() => {
          return this.router.navigate(['login']);
        }),
      ),
    {
      dispatch: false,
    },
  );

  removeUserLocally$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAction.removeUserLocally),
        mergeMap(() => {
          this.userService.removeFromLocalStorage('user');
          return EMPTY;
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  fetchUserDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.fetchUserDetail),
      switchMap(() =>
        this.genericService.get<User>(this.configService.user).pipe(
          tap((user) => {
            this.translationService.setActiveLang(user?.settings.language);
            this.userService.saveUserLocally(user);
          }),
          mergeMap((user) => [
            UserAction.setUser({ payload: user }),
            UserAction.saveServiceSettings({ serviceSettings: user.service }),
            UserAction.connectWebSocket(),
            UserAction.fetchServicesList({ location: user.location?.id }),
          ]),
          catchError((error: unknown) => [
            handleHttpError({ error: error as HttpErrorResponse }),
          ]),
        ),
      ),
    ),
  );

  fetchServicesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.fetchServicesList),
      withLatestFrom(this.ngrxStore.select(selectServiceParam)),
      switchMap(([{ location, date }, serviceParam]) => {
        const params: {
          field: string;
          model: string;
          location?: number;
          date?: string;
        } = {
          field: 'section_level2',
          model: 'order',
        };
        if (location) params['location'] = location;
        params['date'] = date ? date : getFormattedDate();
        return this.genericService
          .get<{
            count: number;
            results: string[];
          }>(this.configService.search, params)
          .pipe(
            mergeMap((res: { count: number; results: string[] }) => {
              const selectedService =
                serviceParam && res.results.includes(serviceParam as string)
                  ? serviceParam
                  : compact(res.results)[0];
              if (
                !serviceParam ||
                !res.results.includes(serviceParam as string)
              ) {
                this.router.navigate([], {
                  relativeTo: this.activatedRoute,
                  queryParams: { service: selectedService },
                });
              }
              return [
                UserAction.setCurrentDate({ date: params['date'] as string }),
                UserAction.setCurrentService({
                  service: selectedService as string,
                }),
                UserAction.setServicesList({ services: res.results }),
                fetchConsumers({}),
                fetchOrders({ params: { service_status: 2 } }),
                fetchOrders({ params: { service_status: 3 } }),
                fetchOrders({ params: { service_status: 4 } }),
                UserAction.fetchAvailableSections({
                  currentService: selectedService as string,
                  date,
                }),
              ];
            }),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  changeCurrentService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.changeCurrentService),
      withLatestFrom(this.ngrxStore.select(selectCurrentDate)),
      tap(([{ service }]) => {
        this.router.navigate([], { queryParams: { service } });
      }),
      switchMap(([{ service }, date]) => [
        clearOrders(),
        UserAction.setCurrentService({ service }),
        UserAction.fetchAvailableSections({ currentService: service, date }),
        fetchConsumers({}),
        fetchOrders({ params: { service_status: 2 } }),
        fetchOrders({ params: { service_status: 3 } }),
        fetchOrders({ params: { service_status: 4 } }),
      ]),
    ),
  );

  fetchAvailableSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.fetchAvailableSections),
      tap(() =>
        this.ngrxStore.dispatch(
          UserAction.setAvailableSectionsLoading({
            availableSectionsLoading: true,
          }),
        ),
      ),
      switchMap(({ currentService, location, date }) => {
        const params: {
          field: string;
          model: string;
          location?: number;
          extra_value?: string;
          date?: string;
        } = {
          field: 'section_level3',
          model: 'order',
        };
        if (location) params['location'] = location;
        if (currentService) params['extra_value'] = currentService;
        params['date'] = date ? date : getFormattedDate();
        return this.genericService
          .get<{
            count: number;
            results: string[];
          }>(this.configService.search, params)
          .pipe(
            mergeMap((res: { count: number; results: string[] }) => [
              UserAction.setAvailableSections({ sections: res.results }),
              UserAction.setAvailableSectionsLoading({
                availableSectionsLoading: false,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
              UserAction.setAvailableSectionsLoading({
                availableSectionsLoading: false,
              }),
            ]),
          );
      }),
    ),
  );

  changeServiceSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.changeServiceSettings),
      withLatestFrom(this.ngrxStore.select(selectUser)),
      switchMap(([{ serviceSettings }, user]) =>
        this.genericService
          .patch<
            { service: Partial<ServiceSettings> },
            User
          >(`${this.configService.userDetail}${user?.id}/`, { service: serviceSettings })
          .pipe(
            mergeMap((user) => [
              UserAction.saveServiceSettings({
                serviceSettings: {
                  categories: user.service?.categories ?? 1,
                  colour_mode: user.service?.colour_mode ?? 1,
                  delete_orders: user.service?.delete_orders ?? false,
                  show_consumer_diets:
                    user.service?.show_consumer_diets ?? false,
                  show_consumer_name: user.service?.show_consumer_name ?? false,
                  show_consumer_profile:
                    user.service?.show_consumer_profile ?? false,
                  show_consumer_room: user.service?.show_consumer_room ?? false,
                  show_consumer_type: user.service?.show_consumer_type ?? false,
                  tables: user.service?.tables ?? [],
                  table_seating: user.service?.table_seating ?? false,
                  table_filter_reservations:
                    user.service?.table_filter_reservations ?? false,
                  hide_checkedin_reservations:
                    user.service?.hide_checkedin_reservations ?? false,
                  table_seating_prefill:
                    user.service?.table_seating_prefill ?? 0,
                },
              }),
              showSnackbarMessage({
                message: this.translationService.translate(this.SAVE_CHANGES),
                button: this.translationService.translate(this.GOT_IT),
              }),
              ...(serviceSettings?.tables
                ? [
                    fetchOrders({ params: { service_status: 2 } }),
                    fetchOrders({ params: { service_status: 3 } }),
                    fetchOrders({ params: { service_status: 4 } }),
                  ]
                : []),
              ...(serviceSettings?.table_filter_reservations !== undefined ||
              (serviceSettings?.tables &&
                user.service?.table_filter_reservations)
                ? [fetchConsumers({})]
                : []),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          ),
      ),
    ),
  );

  fetchTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.fetchTables),
      withLatestFrom(this.ngrxStore.select(selectUserLocation)),
      switchMap(([, location]) => {
        const params: {
          model: string;
          field: string;
          location?: number;
        } = {
          model: 'service',
          field: 'table_number',
        };

        if (location) params['location'] = location;
        return this.genericService
          .get<{ count: number; results: string[] }>(
            this.configService.searchModel,
            {
              ...params,
            },
          )
          .pipe(
            mergeMap((res: { count: number; results: string[] }) => [
              UserAction.setTables({ tables: res.results }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  connectWebSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.connectWebSocket),
      withLatestFrom(this.ngrxStore.select(selectUser)),
      switchMap(([, user]) => {
        this.websockets.connect(`${user!.organisation.identifier}_tickets`);
        return this.websockets.messagesSubject$;
      }),
      mergeMap((message) => [processWebsocketsData({ message })]),
    ),
  );

  disconnectWebSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAction.disconnectWebSocket),
        switchMap(() => {
          this.websockets.disconnect();
          return [];
        }),
      ),
    { dispatch: false },
  );

  reportWebSocketError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.reportWebSocketError),
      switchMap(() => [
        setGlobalError({
          error: this.translationService.translate(
            'shared.errors.websockets-error',
          ),
        }),
      ]),
    ),
  );

  readonly SAVE_CHANGES = 'settings.changes-saved';
  readonly GOT_IT = 'settings.got-it';

  constructor(
    private actions$: Actions,
    private ngrxStore: Store<IState>,
    private userService: UserService,
    private configService: ConfigService,
    private genericService: GenericsService,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslocoService,
    private websockets: WebsocketService,
  ) {}
}
