<div class="header">
  <img class="resto-img" src="assets/img/service.svg" alt="Service" />
  <h1>{{ 'login.welcome' | transloco }}</h1>
</div>
<mat-card class="card mat-elevation-z2">
  <resto-form-with-errors
    restoServerFormError
    class="form"
    [formGroup]="loginForm"
    formId="loginForm"
  >
    <div class="fields">
      <resto-field-with-errors
        icon="email"
        type="email"
        [label]="'login.email.placeholder' | transloco"
        translationKey="login.email.errors"
        formControlName="email"
        [required]="true"
      ></resto-field-with-errors>
      <resto-field-with-errors
        icon="lock_outline"
        type="password"
        [label]="'login.password.placeholder' | transloco"
        formControlName="password"
        translationKey="login.password.errors"
        [required]="true"
      ></resto-field-with-errors>
    </div>
    <button
      class="submit-button"
      mat-flat-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
      [disabled]="spinnerState$ | async"
    >
      @if (spinnerState$ | async) {
        <span>
          <mat-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="24"
          ></mat-spinner>
        </span>
      }
      {{ 'login.login-button' | transloco }}
    </button>
  </resto-form-with-errors>
  <div class="links remind-password">
    <a
      href="https://menu.menutech.com/reset-password"
      class="remind-password"
      target="_blank"
      >{{ 'login.remind-password' | transloco }}</a
    >
  </div>
</mat-card>
<div class="info">
  <mat-icon class="info-icon">info</mat-icon>
  <div class="info-text">
    {{ 'login.use' | transloco }}
    <a
      href="https://menu.menutech.com/"
      class="menutech-link"
      target="_blank"
      >{{ 'login.menutech' | transloco }}</a
    >
    {{ 'login.access-credentials' | transloco }}
  </div>
</div>
