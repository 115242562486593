<div class="settings-header">
  <div class="current-user">
    <mat-icon>cruelty_free</mat-icon>
    <span class="name"
      >{{ user?.first_name }}
      <span class="name-last">{{ user?.last_name }}</span></span
    >
  </div>
  <button class="toggle-button" mat-button (click)="toggle.emit()">
    <mat-icon class="toggle-icon">menu_open</mat-icon>
  </button>
</div>

<h3 class="sidebar-title">
  {{ 'settings.limit-tables-displayed.title' | transloco }}
</h3>
@if (serviceSettings) {
  <resto-filter-tags
    [fullWidth]="true"
    [initialTags]="serviceSettings.tables"
    [tags]="tables"
    (changeSelection)="changeTables($event)"
  ></resto-filter-tags>
}

<h3 class="sidebar-title">
  {{ 'settings.workflow-settings.title' | transloco }}
</h3>
<form [formGroup]="form">
  <mat-radio-group name="immediate_done" formControlName="immediate_done">
    <mat-radio-button
      class="radio-button"
      (change)="radioChange($event, true)"
      [value]="false"
      >{{ 'settings.workflow-settings.options.standard' | transloco }}
    </mat-radio-button>
    <mat-radio-button
      class="radio-button"
      (change)="radioChange($event, true)"
      [value]="true"
      >{{ 'settings.workflow-settings.options.immediate-done' | transloco }}
    </mat-radio-button>
  </mat-radio-group>
</form>

<h3 class="sidebar-title">
  {{ 'settings.reservations-confirmation-settings.title' | transloco }}
</h3>
<form [formGroup]="form">
  <mat-radio-group name="table_seating" formControlName="table_seating">
    <mat-radio-button
      class="radio-button"
      (change)="radioChange($event)"
      [value]="false"
      >{{
        'settings.reservations-confirmation-settings.options.no-table-require'
          | transloco
      }}
    </mat-radio-button>
    <mat-radio-button
      class="radio-button"
      (change)="radioChange($event)"
      [value]="true"
      >{{
        'settings.reservations-confirmation-settings.options.table-require'
          | transloco
      }}
    </mat-radio-button>
  </mat-radio-group>
</form>
<div class="table-setting">
  <form [formGroup]="form">
    <h4 class="mat-body-strong">
      {{
        'settings.reservations-confirmation-settings.table-filters' | transloco
      }}
    </h4>
    <mat-checkbox
      class="checkbox"
      color="primary"
      (change)="consumerInfoChange($event)"
      name="table_filter_reservations"
      formControlName="table_filter_reservations"
    >
      {{
        'settings.reservations-confirmation-settings.table-filters-reservations'
          | transloco
      }}
    </mat-checkbox>
    <mat-checkbox
      class="checkbox"
      color="primary"
      (change)="consumerInfoChange($event)"
      name="hide_checkedin_reservations"
      formControlName="hide_checkedin_reservations"
    >
      {{
        'settings.reservations-confirmation-settings.hide-checkedin-reservations'
          | transloco
      }}
    </mat-checkbox>
    <h4 class="mat-body-strong">
      {{
        'settings.reservations-confirmation-settings.table-settings' | transloco
      }}
    </h4>
    <mat-radio-group
      name="table_seating_prefill"
      formControlName="table_seating_prefill"
    >
      <mat-radio-button
        class="radio-button"
        (change)="radioChange($event)"
        [value]="0"
        >{{
          'settings.reservations-confirmation-settings.options.starts-blank'
            | transloco
        }}</mat-radio-button
      >
      @if (form.controls['table_seating'].value === true) {
        <mat-radio-button
          class="radio-button"
          (change)="radioChange($event)"
          [value]="1"
          >{{
            'settings.reservations-confirmation-settings.options.prefill-previous'
              | transloco
          }}</mat-radio-button
        >
      }
      @if (hasOrdertakingTableFeature) {
        <mat-radio-button
          class="radio-button"
          (change)="radioChange($event)"
          [value]="2"
          >{{
            'settings.reservations-confirmation-settings.options.prefill-saved'
              | transloco
          }}
        </mat-radio-button>
      }
    </mat-radio-group>
  </form>
</div>
<div class="action-items">
  <button mat-button class="underline" (click)="saveSettings()">
    {{ 'settings.save-selection' | transloco }}
  </button>
</div>

<h3 class="sidebar-title">
  {{ 'settings.shown-consumer-information.title' | transloco }}
</h3>
<form [formGroup]="form">
  <mat-checkbox
    class="checkbox"
    color="primary"
    (change)="consumerInfoChange($event)"
    name="show_consumer_name"
    formControlName="show_consumer_name"
  >
    {{ 'settings.shown-consumer-information.options.full-name' | transloco }}
  </mat-checkbox>
  <mat-checkbox
    class="checkbox"
    color="primary"
    (change)="consumerInfoChange($event)"
    name="show_consumer_room"
    formControlName="show_consumer_room"
  >
    {{ 'settings.shown-consumer-information.options.room-number' | transloco }}
  </mat-checkbox>
  <mat-checkbox
    class="checkbox"
    color="primary"
    (change)="consumerInfoChange($event)"
    name="show_consumer_diets"
    formControlName="show_consumer_diets"
  >
    {{
      'settings.shown-consumer-information.options.diet-consumer' | transloco
    }}
  </mat-checkbox>
  <mat-checkbox
    class="checkbox"
    color="primary"
    (change)="consumerInfoChange($event)"
    name="show_consumer_type"
    formControlName="show_consumer_type"
  >
    {{
      'settings.shown-consumer-information.options.consumer-type' | transloco
    }}
  </mat-checkbox>
  <mat-checkbox
    class="checkbox"
    color="primary"
    (change)="consumerInfoChange($event)"
    name="show_consumer_profile"
    formControlName="show_consumer_profile"
  >
    {{
      'settings.shown-consumer-information.options.consumer-profile' | transloco
    }}
  </mat-checkbox>
</form>
<button mat-button class="underline" (click)="saveSettings()">
  {{ 'settings.save-selection' | transloco }}
</button>

<h3 class="sidebar-title">
  {{ 'settings.deleting-orders-settings.title' | transloco }}
</h3>
<form [formGroup]="form">
  <mat-radio-group name="delete_orders" formControlName="delete_orders">
    <mat-radio-button
      class="radio-button"
      (change)="radioChange($event, true)"
      [value]="false"
      >{{
        'settings.deleting-orders-settings.options.does-not-delete-order-on-order-taking'
          | transloco
      }}</mat-radio-button
    >
    <mat-radio-button
      class="radio-button"
      (change)="radioChange($event, true)"
      [value]="true"
      >{{
        'settings.deleting-orders-settings.options.delete-order-on-order-taking'
          | transloco
      }}
    </mat-radio-button>
  </mat-radio-group>
</form>
