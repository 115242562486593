import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { IState } from 'src/app/reducers';
import { logoutUser } from 'src/app/reducers/user/user.actions';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'resto-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslocoPipe],
})
export class FooterComponent {
  @Input() showLogout!: boolean;

  constructor(
    private ngrxStore: Store<IState>,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  logOut(): void {
    this.ngrxStore.dispatch(logoutUser());
  }

  goBackToOrderTaking(): void {
    this.document.location.href = 'https://menu.menutech.com/login';
  }
}
