import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { Store } from '@ngrx/store';
import { ServiceSettings, User } from 'src/app/models/user';
import { IState } from 'src/app/reducers';
import {
  changeServiceSettings,
  saveServiceSettings,
} from 'src/app/reducers/user/user.actions';
import { TranslocoPipe } from '@ngneat/transloco';
import { AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FilterTagsComponent } from 'src/app/shared/components/filter-tags/filter-tags.component';

@Component({
  selector: 'resto-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatRadioModule,
    MatCheckboxModule,
    TranslocoPipe,
    FilterTagsComponent,
  ],
})
export class SettingsComponent implements OnChanges {
  @Input() currentService!: string | null | undefined;
  @Input() hasOrdertakingTableFeature!: boolean | null;
  @Input() serviceSettings!: ServiceSettings | null;
  @Input() user!: User | null;
  @Input() tables!: string[] | null;
  @Output() toggle = new EventEmitter<void>();
  @Output() fetchTables = new EventEmitter<void>();

  textSizes = [100, 125, 150, 175];
  pageSizes = [5, 10, 20, 50];

  form: FormGroup = this.formBuilder.group({
    show_consumer_name: [false],
    show_consumer_room: [false],
    show_consumer_diets: [false],
    show_consumer_type: [false],
    show_consumer_profile: [false],
    immediate_done: [false],
    table_filter_reservations: [false],
    hide_checkedin_reservations: [false],
    table_seating: [false],
    table_seating_prefill: [0],
    delete_orders: [false],
  });

  constructor(
    private formBuilder: FormBuilder,
    private ngrxStore: Store<IState>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('serviceSettings' in changes && this.serviceSettings) {
      const { colour_mode, categories, tables, ...data } =
        this.serviceSettings!;
      this.form.patchValue(data);
    }
  }

  changeTables(tables: string[]): void {
    this.ngrxStore.dispatch(
      changeServiceSettings({
        serviceSettings: { tables },
      }),
    );
  }

  consumerInfoChange(event: MatCheckboxChange): void {
    this.ngrxStore.dispatch(
      saveServiceSettings({
        serviceSettings: {
          [event.source.name!]: this.form.value[event.source.name!],
        },
      }),
    );
  }

  radioChange(event: MatRadioChange, autoSave = false): void {
    this.form.controls[event.source.name].patchValue(event.value);
    const data = Object.assign({}, this.form.value);
    this.ngrxStore.dispatch(saveServiceSettings({ serviceSettings: data }));
    if (autoSave) {
      this.ngrxStore.dispatch(
        changeServiceSettings({
          serviceSettings: { [event.source.name]: event.value },
        }),
      );
    }
  }

  saveSettings(): void {
    const serviceSettings = Object.assign({}, this.form.value);
    this.ngrxStore.dispatch(changeServiceSettings({ serviceSettings }));
  }
}
