import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MatOptionSelectionChange,
  MatOptionModule,
} from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { de, enUS, es, fr, it } from 'date-fns/locale';
import { compact } from 'lodash-es';
import { IState } from 'src/app/reducers';
import { clearOrders } from 'src/app/reducers/orders/orders.actions';
import {
  changeCurrentService,
  fetchServicesList,
  setCurrentDate,
  setCurrentService,
} from 'src/app/reducers/user/user.actions';
import { getFormattedDate } from 'src/app/shared/utils.functions';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'resto-navigation-filters',
  templateUrl: './navigation-filters.component.html',
  styleUrls: ['./navigation-filters.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
  ],
})
export class NavigationFiltersComponent implements OnChanges {
  @Input() language = 'en';
  @Input() serviceList!: string[] | null;
  @Input() userLocation!: number | null | undefined;

  date = new FormControl(new Date());
  localeMap = {
    en: enUS,
    de: de,
    fr: fr,
    es: es,
    it: it,
  };
  selectFormControl = new FormControl('');
  services: string[] = [];
  selectedService: string = '';
  selectedDate: string = '';

  constructor(
    private activatedRout: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private ngrxStore: Store<IState>,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('serviceList' in changes && this.serviceList) {
      this.services = compact(this.serviceList);
      this.selectedService = this.services[0];
      const currentService =
        this.activatedRout.snapshot.queryParamMap.get('service');
      if (currentService && this.services.includes(currentService)) {
        this.selectedService = currentService;
      }
      this.selectFormControl.setValue(this.selectedService);
      this.ngrxStore.dispatch(
        setCurrentService({ service: this.selectedService }),
      );
    }
    if ('language' in changes) {
      this.dateAdapter.setLocale(
        this.localeMap[this.language as 'en' | 'de' | 'fr' | 'es' | 'it'],
      );
    }
  }

  dateChange(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const date = getFormattedDate(event.value);
      this.selectedDate = date;
      this.ngrxStore.dispatch(clearOrders());
      this.ngrxStore.dispatch(setCurrentDate({ date }));
      this.ngrxStore.dispatch(
        fetchServicesList({ location: this.userLocation, date }),
      );
    }
  }

  selectService(event: MatOptionSelectionChange<string>): void {
    if (event.isUserInput) {
      this.selectedService = event.source.value;
      this.ngrxStore.dispatch(
        changeCurrentService({ service: event.source.value }),
      );
    }
  }
}
