import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import {
  selectColumns,
  selectCurrentService,
  selectIsRequiresTableSeating,
  selectSections,
  selectTablesToShow,
} from 'src/app/reducers/user/user.selectors';
import { IState } from 'src/app/reducers';
import {
  deleteOrders,
  fetchOrders,
  updateOrderComment,
  updateOrderStatus,
} from 'src/app/reducers/orders/orders.actions';
import {
  selectDoneOrdersItems,
  selectIsTableDataLoadingDone,
  selectTotalDoneOrdersCount,
} from 'src/app/reducers/orders/orders.selectors';
import { OrdersParams } from 'src/app/models/orders';
import { selectSectionFilter } from 'src/app/reducers/shared/shared.selectors';
import { setSectionFilter } from 'src/app/reducers/shared/shared.actions';
import { AsyncPipe } from '@angular/common';
import { DoneOrdersTableComponent } from '../done-orders-table/done-orders-table.component';

@Component({
  selector: 'resto-done-orders',
  templateUrl: './done-orders.component.html',
  styleUrls: ['./done-orders.component.scss'],
  standalone: true,
  imports: [DoneOrdersTableComponent, AsyncPipe],
})
export class DoneOrdersComponent implements OnInit {
  availableSections$ = this.ngrxStore.select(selectSections);
  doneOrders$ = this.ngrxStore.select(selectDoneOrdersItems);
  isRequireTableSeating$ = this.ngrxStore.select(selectIsRequiresTableSeating);
  isTableDataLoading$ = this.ngrxStore.select(selectIsTableDataLoadingDone);
  lastParams!: Partial<OrdersParams>;
  selectedColumns$ = this.ngrxStore.select(selectColumns);
  sectionFilter$ = this.ngrxStore.select(selectSectionFilter);
  tablesToShow$ = this.ngrxStore.select(selectTablesToShow);
  totalDoneOrdersCount$ = this.ngrxStore.select(selectTotalDoneOrdersCount);

  constructor(private ngrxStore: Store<IState>) {}

  ngOnInit(): void {
    this.ngrxStore
      .select(selectCurrentService)
      .pipe(take(1))
      .subscribe((val) => {
        if (val !== undefined)
          this.ngrxStore.dispatch(
            fetchOrders({ params: { service_status: 4 } }),
          );
      });
  }

  retrieveOrders(params: Partial<OrdersParams>) {
    this.lastParams = params;
    this.ngrxStore.dispatch(
      fetchOrders({
        params: {
          ...params,
          service_status: 4,
        },
      }),
    );
  }

  undoOrder(data: { ids: number[] }): void {
    this.ngrxStore.dispatch(
      updateOrderStatus({
        ids: data.ids,
        previous_status: 4,
        new_status: 3,
      }),
    );
  }

  deleteOrder({
    ids,
    count,
    section,
  }: {
    ids: number[];
    count: number;
    section: string;
  }): void {
    this.ngrxStore.dispatch(
      deleteOrders({ ids, service_status: 4, count, section }),
    );
  }

  filterBySection(section: string | null): void {
    this.ngrxStore.dispatch(setSectionFilter({ section }));
    [2, 3, 4].forEach((status) => {
      this.ngrxStore.dispatch(
        fetchOrders({
          params: {
            service_status: status,
          },
        }),
      );
    });
  }

  updateComment(data: { id: number; comment: string }): void {
    this.ngrxStore.dispatch(updateOrderComment(data));
  }
}
