<div class="wrapper">
  @if (showLabel) {
    <span class="label"
      >{{ label }}
      @if (value !== true) {
        <span>: </span>
      }
    </span>
  }
  @if (value !== true) {
    <span class="value-wrapper">
      <span
        class="value"
        [class.strikethrough]="valueOverwritten"
        [innerHtml]="value"
      ></span>
      @if (valueOverwritten) {
        <mat-icon class="overwritten">announcement</mat-icon>
      }
      &nbsp;<span class="value overwritten">{{ valueOverwritten }}</span>
    </span>
  }
</div>
