import { Routes } from '@angular/router';
import { AccessForbiddenComponent } from './authentication/access-forbidden/access-forbidden.component';
import { DoneOrdersComponent } from './components/done/done-orders/done-orders.component';
import { FiredOrdersComponent } from './components/fired/fired-orders/fired-orders.component';
import { LoginComponent } from './authentication/login/login.component';
import { loginGuard } from './services/guards/login.guard';
import { OrdersComponent } from './components/order/orders/orders.component';
import { ReservationsComponent } from './components/reservation/reservations/reservations.component';
import { serviceGuard } from './services/guards/service-guard.guard';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'forbidden',
    component: AccessForbiddenComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'fired',
    component: FiredOrdersComponent,
    canActivate: [loginGuard, serviceGuard],
  },
  {
    path: 'reservations',
    component: ReservationsComponent,
    canActivate: [loginGuard, serviceGuard],
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [loginGuard, serviceGuard],
  },
  {
    path: 'done',
    component: DoneOrdersComponent,
    canActivate: [loginGuard, serviceGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
