import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { GroupedOrder } from 'src/app/models/fired';
import { GroupedTickets, Order } from 'src/app/models/orders';
import * as OrdersActions from './orders.actions';

export interface OrdersState {
  ordered: {
    items: GroupedTickets[];
    loading: boolean;
    tempCount: number | null;
  };
  fired: {
    items: GroupedOrder[];
    loading: boolean;
    tempCount: number | null;
  };
  done: {
    items: GroupedOrder[];
    loading: boolean;
    tempCount: number | null;
  };
}

export const initialState: OrdersState = {
  ordered: {
    items: [],
    loading: false,
    tempCount: null,
  },
  fired: {
    items: [],
    loading: false,
    tempCount: null,
  },
  done: {
    items: [],
    loading: false,
    tempCount: null,
  },
};

const reducerKey: { [status: number]: keyof OrdersState } = {
  2: 'ordered',
  3: 'fired',
  4: 'done',
};

const _ordersReducer = createReducer(
  initialState,
  on(OrdersActions.clearOrders, () => ({
    ...initialState,
  })),
  on(OrdersActions.removeOrders, (state, { ids, service_status }) => ({
    ...state,
    [reducerKey[service_status]]: {
      ...state[reducerKey[service_status]],
      tempCount: null,
      items: state[reducerKey[service_status]].items
        .map((item) => ({
          ...item,
          orders: item.orders.filter(
            (item) => !ids.some((id) => item.ids.includes(id)),
          ),
        }))
        .filter((item) => item.orders.length > 0),
    },
  })),
  on(OrdersActions.setOrders, (state, { items, service_status }) => {
    return {
      ...state,
      [reducerKey[service_status]]: {
        ...state[reducerKey[service_status]],
        items,
      },
    };
  }),
  on(
    OrdersActions.changeOrderStatus,
    (state, { ids, previous_status, new_status, items }) => {
      if (!items.length) return state;

      const previousStateKey = reducerKey[previous_status];
      const newStateKey = reducerKey[new_status];

      // Deep copy the state
      const newState = cloneDeep(state);

      if (previous_status !== 1) {
        // Remove the orders from the previous status
        newState[previousStateKey].tempCount = null;
        newState[previousStateKey].items = newState[previousStateKey].items
          .map((item) => ({
            ...item,
            orders: item.orders.filter(
              (order) => !ids.some((id) => order.ids.includes(id)),
            ),
          }))
          .filter((item) => item.orders.length > 0);
      }

      // Add these orders to the new status
      items.forEach((order) => {
        const itemIndex = newState[newStateKey].items.findIndex(
          (item) => item.table_number === order.service_table_number,
        );
        if (itemIndex !== -1) {
          newState[newStateKey].items[itemIndex].orders.push(order);
        } else {
          newState[newStateKey].items.push({
            table_number: order.service_table_number,
            orders: [order],
          });
        }
      });

      // Sort the items in the new status
      newState[newStateKey].tempCount = null;
      newState[newStateKey].items.forEach((item) => {
        item.orders.sort(
          (a, b) =>
            a.section_sorting - b.section_sorting ||
            a.item_sorting - b.item_sorting ||
            a.item_option - b.item_option,
        );
      });

      return newState;
    },
  ),
  on(OrdersActions.setOrdersLoading, (state, { loading, service_status }) => ({
    ...state,
    [reducerKey[service_status]]: {
      ...state[reducerKey[service_status]],
      loading,
    },
  })),
  on(OrdersActions.setNewSpecialOrder, (state, { order, service_status }) => {
    const stateKey = reducerKey[service_status];
    const newItems = cloneDeep(state[stateKey].items);
    newItems.forEach((el) => {
      if (el.table_number === order.service_table_number) {
        el.orders.push(order);
      }
    });
    return {
      ...state,
      [stateKey]: {
        ...state[stateKey],
        items: newItems,
      },
    };
  }),
  on(OrdersActions.setNewOrders, (state, { items, service_status }) => {
    const stateKey = reducerKey[service_status];
    const newItems: GroupedOrder[] = cloneDeep([...state[stateKey].items]);
    items.forEach((item) => {
      const index = state[stateKey].items.findIndex(
        (i) =>
          i.table_number === item.service_table_number ||
          i.table_number === item.table_number,
      );
      if (index !== -1) {
        newItems.forEach((el) => {
          if (
            el.table_number === item.service_table_number ||
            item.table_number
          ) {
            el.orders.push(item);
          }
        });
      } else {
        newItems.push({
          table_number: item.service_table_number,
          orders: [item],
        });
      }
    });
    return {
      ...state,
      [stateKey]: {
        ...state[stateKey],
        items: newItems,
        tempCount: null,
      },
    };
  }),
  on(OrdersActions.changeOrders, (state, { items, service_status }) => {
    const stateKey = reducerKey[service_status];
    let updatedItems = cloneDeep(state[stateKey].items);
    const groupedOrders: { [key: string]: Order[] } = {};
    items.forEach((order) => {
      if (groupedOrders[order.service_table_number]) {
        groupedOrders[order.service_table_number].push(order);
      } else {
        groupedOrders[order.service_table_number] = [order];
      }
    });
    Object.keys(groupedOrders).forEach((group) => {
      updatedItems = updatedItems.map((item) => {
        if (item.table_number === group) {
          return {
            ...item,
            orders: item.orders.map((order) => {
              const newOrder = groupedOrders[group].find((g) =>
                g.ids.includes(order.ids[0]),
              );
              if (newOrder) {
                return newOrder;
              }
              return order;
            }),
          };
        }
        return item;
      });
    });
    return {
      ...state,
      [stateKey]: {
        ...state[stateKey],
        items: updatedItems,
      },
    };
  }),
  on(OrdersActions.clearAllOrders, () => ({ ...initialState })),
);

export function ordersReducer(state: OrdersState | undefined, action: Action) {
  return _ordersReducer(state, action);
}
