<div class="rfid-details">
  @if (!showThankYouMessage) {
    <mat-form-field>
      <mat-icon matPrefix> money </mat-icon>
      <mat-label>{{
        'reservations.table.rfid.identification' | transloco
      }}</mat-label>
      <input
        (keyup.enter)="fetchRfidDetail()"
        matInput
        type="text"
        name="rfid"
        [formControl]="rfid"
      />
      @if (rfidDetails?.vip) {
        <button mat-icon-button matSuffix>
          <mat-icon class="vip-user">local_activity</mat-icon>
        </button>
      }
    </mat-form-field>
    @if (!isRfidDetailsLoading && rfidDetailsCount === null) {
      <div class="qr-scanner">
        <mat-icon class="qr-icon"> qr_code_scanner</mat-icon>
      </div>
      <ng-container>
        {{
          'reservations.table.rfid.place-card-on-rfid-reader-to-see-consumer'
            | transloco
        }}
      </ng-container>
    }
    @if (rfidDetailsCount === 0) {
      <div>
        {{
          'reservations.table.rfid.no-reservation-found-under-rfid' | transloco
        }}
      </div>
    }
    @if (rfidDetailsCount !== 0 && rfidDetails && isRequireTableSeating) {
      <div>
        <p>
          <mat-icon
            class="send-icon"
            [ngClass]="rfidDetails.vip ? 'vip-user' : 'user'"
            >send</mat-icon
          >
          {{
            rfidDetails.service_status
              ? ('reservations.table.rfid.you-already-checked-in-change-table-seating'
                | transloco)
              : ('reservations.table.rfid.you-must-assigned-table-to-confirm-reservation'
                | transloco)
          }}
        </p>
        @if (
          rfidDetails &&
          (ordertakingTableFeature || rfidDetails.service_status_table)
        ) {
          <p class="saved-table">
            {{ 'reservations.table.table-number-saved' | transloco }}
            @if (ordertakingTableFeature && !rfidDetails.service_status_table) {
              <span class="saved-table">
                {{ rfidDetails.table_number }}
              </span>
            } @else {
              {{ rfidDetails.service_status_table }}
              @if (
                ordertakingTableFeature &&
                rfidDetails.service_status_table !== rfidDetails.table_number
              ) {
                <button
                  mat-icon-button
                  [matTooltip]="
                    'reservations.table.actions.save-favourite' | transloco
                  "
                  (click)="saveTable(rfidDetails)"
                >
                  <mat-icon>download</mat-icon>
                </button>
              }
            }
          </p>
        }
        <div class="assign-table-button">
          @if (rfidDetails) {}
          <button
            [matMenuTriggerFor]="tableInput"
            class="assign-table"
            mat-flat-button
            color="primary"
          >
            <ng-container>
              {{
                rfidDetails.service_status
                  ? ('reservations.table.actions.change-table' | transloco)
                  : ('reservations.table.actions.assign-to-table' | transloco)
              }}
              <mat-icon class="table-icon">table_bar</mat-icon>
            </ng-container>
          </button>
          <mat-menu #tableInput>
            <mat-form-field class="table-input" subscriptSizing="dynamic">
              <input
                (click)="$event.stopPropagation()"
                matInput
                type="text"
                name="table_number"
                [placeholder]="'reservations.table.table-number' | transloco"
                [formControl]="tableNumber"
              />
              <button mat-icon-button matSuffix (click)="updateServiceStatus()">
                <mat-icon>check</mat-icon>
              </button>
            </mat-form-field>
          </mat-menu>
          <button mat-button (click)="cancelRfidInput()">
            {{ 'reservations.table.rfid.go-back' | transloco }}
          </button>
        </div>
      </div>
    }
  } @else {
    <div class="thank-you-message">
      <button
        mat-fab
        extended
        color="primary"
        class="reservation-status"
        [ngClass]="rfidDetails?.vip ? 'vip-user-button' : 'non-vip-user-button'"
      >
        <mat-icon class="icon">send</mat-icon>
        <mat-icon class="icon">check</mat-icon>
      </button>
      <div class="thank-you-message-detail">
        {{
          rfidDetails?.service_status
            ? ('reservations.table.rfid.you-already-checked-in-for-service'
              | transloco)
            : ('reservations.table.rfid.thank-you-signed-in' | transloco)
        }}
      </div>
    </div>
    <button mat-button (click)="clearRfidFieldEvent.emit()">
      {{ 'reservations.table.rfid.go-back' | transloco }}
    </button>
  }
  @if (isRfidDetailsLoading) {
    <div class="spinner-container">
      <mat-spinner class="loading-spinner" mode="indeterminate"></mat-spinner>
    </div>
  }
</div>
