import { Action, createReducer, on } from '@ngrx/store';
import {
  FieldErrors,
  FormErrors,
  NonFieldError,
} from 'src/app/models/authentication';
import * as SharedActions from './shared.actions';

export interface SharedState {
  form_errors: FieldErrors | FormErrors;
  global_error: string;
  section_filter: string | null;
}

const initialState: SharedState = {
  form_errors: {},
  global_error: '',
  section_filter: null,
};

const _sharedReducer = createReducer(
  initialState,
  on(SharedActions.setFormErrors, (state, { error }) => {
    const formErrors = state?.form_errors;
    const formErrorsNonField = formErrors?.non_field_errors as NonFieldError[];

    const newFormErrors: FieldErrors | FormErrors = formErrors
      ? { ...formErrors }
      : {};
    newFormErrors.non_field_errors = formErrorsNonField
      ? [...formErrorsNonField, error]
      : [error];
    return {
      ...state,
      form_errors: newFormErrors,
    };
  }),
  on(SharedActions.setFieldErrors, (state, { payload }) => ({
    ...state,
    form_errors: { ...state.form_errors, ...payload },
  })),
  on(SharedActions.clearFormAndFieldErrors, (state, { payload: errorName }) => {
    const { [errorName]: _, ...newFormErrors } =
      state?.form_errors as FieldErrors;
    return {
      ...state,
      form_errors: newFormErrors,
    };
  }),
  on(SharedActions.setGlobalError, (state, { error }) => ({
    ...state,
    global_error: error,
  })),
  on(SharedActions.setSectionFilter, (state, { section }) => ({
    ...state,
    section_filter: section,
  })),
);

export function sharedReducer(state: SharedState | undefined, action: Action) {
  return _sharedReducer(state, action);
}
