import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ordersReducer, OrdersState } from './orders/orders.reducer';
import {
  reservationsReducer,
  ReservationsState,
} from './reservations/reservations.reducer';
import { sharedReducer, SharedState } from './shared/shared.reducer';
import { userReducer, UserState } from './user/user.reducer';

export interface IState {
  orders: OrdersState;
  reservations: ReservationsState;
  router: RouterReducerState<any>;
  shared: SharedState;
  user: UserState;
}

export const reducers: ActionReducerMap<IState> = {
  orders: ordersReducer,
  reservations: reservationsReducer,
  router: routerReducer,
  shared: sharedReducer,
  user: userReducer,
};
