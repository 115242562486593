import { formatDate } from '@angular/common';
import { ApiFormErrors } from 'src/app/models/authentication';

import { GroupedTickets } from '../models/orders';
import { LANGUAGES } from './constants/languages';
import { environment } from 'src/environments/environment';

/**
 * Map generic error response object to ApiFormErrors
 * @param errors object with string keys and string or string array values
 * @returns object with field errors (string value) and form errors (mapped to non_field_errors with string array value)
 */
export function extractFormErrors(errors: any): ApiFormErrors {
  const OTHER_FORM_ERRORS = [`message`, `token`, `error`];
  const errorObj: ApiFormErrors = {};
  if (OTHER_FORM_ERRORS.some((key) => key in errors)) {
    errors = Object.entries(errors).reduce((acc, [key, value]) => {
      if (OTHER_FORM_ERRORS.includes(key)) {
        acc['non_field_errors'] = [value] as string[];
      } else {
        acc[key] = value as string | string[];
      }
      return acc;
    }, errorObj);
  }
  return errors;
}

export function getFormattedDate(date?: Date): string {
  return formatDate(date ?? new Date(), 'YYYY-MM-dd', 'en-US');
}

export function filterLang(lang?: string): keyof typeof LANGUAGES {
  if (lang && Object.keys(LANGUAGES).includes(lang)) return lang as LANGUAGES;
  return LANGUAGES.en;
}

export function flattenArray(
  obj: Array<any>,
  name?: string,
): { [key: string]: any } {
  return obj.reduce((acc: { [key: string]: any }, curr: any, index: number) => {
    const newName = name ? `${name}_${index}` : index.toString();
    if (curr !== obj && (Array.isArray(curr) || curr instanceof Object)) {
      return {
        ...acc,
        ...flattenObject(curr, newName),
      };
    }
    acc[newName] = curr;
    return acc;
  }, {});
}

export function flattenObject(
  obj: object | Array<any>,
  name?: string,
): { [key: string]: any } {
  if (Array.isArray(obj)) {
    return flattenArray(obj, name);
  } else if (obj instanceof Object) {
    return Object.entries(obj).reduce((acc: any, curr) => {
      const newName = name ? `${name}_${curr[0]}` : curr[0];
      if (
        curr[1] !== obj &&
        (Array.isArray(curr[1]) || curr[1] instanceof Object)
      ) {
        return {
          ...acc,
          ...flattenObject(curr[1], newName),
        };
      }
      if (name) {
        acc[`${name}_${curr[0]}`] = curr[1];
      } else {
        acc[curr[0]] = curr[1];
      }
      return acc;
    }, {});
  }
  return obj;
}

export function calculateTotalOrders(orders: GroupedTickets[] | null): number {
  return orders?.reduce((a, b) => a + b.orders.length, 0) ?? 0;
}

export function parseSupportedLanguage(lang: string): string {
  switch (lang) {
    case 'en':
      return 'en';
    case 'it':
      return 'it';
    case 'de':
      return 'de';
    case 'fr':
      return 'fr';
    case 'es':
      return 'es';
    default:
      return 'en';
  }
}

export function getLocalIsoTimestamp(): string {
  const now = new Date();
  const localOffsetMinutes = now.getTimezoneOffset();

  const offsetHours = Math.floor(Math.abs(localOffsetMinutes) / 60);
  const offsetMinutes = Math.abs(localOffsetMinutes) % 60;
  const sign = localOffsetMinutes < 0 ? '+' : '-';
  const offset =
    sign +
    String(offsetHours).padStart(2, '0') +
    ':' +
    String(offsetMinutes).padStart(2, '0');

  return (
    now.getFullYear() +
    '-' +
    String(now.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(now.getDate()).padStart(2, '0') +
    'T' +
    String(now.getHours()).padStart(2, '0') +
    ':' +
    String(now.getMinutes()).padStart(2, '0') +
    ':' +
    String(now.getSeconds()).padStart(2, '0') +
    '.' +
    String(now.getMilliseconds()).padStart(3, '0') +
    offset
  );
}

export function getTimeBasedNumber(min: number, max: number) {
  const currentMilliseconds = Date.now();
  // Get today's date at midnight as reference time
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const referenceMilliseconds = today.getTime();
  const elapsedMilliseconds = currentMilliseconds - referenceMilliseconds;
  // Scale and shift the time to fit within the target range
  return Math.floor((elapsedMilliseconds / 1000) % (max - min + 1)) + min;
}

export function getDeviceId(): string {
  let deviceId = localStorage.getItem(environment.deviceIdKey);
  if (!deviceId) {
    deviceId = crypto.randomUUID();
    localStorage.setItem(environment.deviceIdKey, deviceId);
  }
  return deviceId;
}
