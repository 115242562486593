<div class="table mat-elevation-z4">
  <div class="filters">
    <div class="select-order">
      @if (sections?.length) {
        <ng-container class="select-order">
          <span class="filter-sections-label">{{
            'done.table.filters.show-orders' | transloco
          }}</span>
          <mat-form-field
            class="services field-density-3"
            appearance="fill"
            subscriptSizing="dynamic"
          >
            <mat-select
              [formControl]="selectOrderFormControl"
              [placeholder]="'orders.table.filters.all' | transloco"
            >
              <mat-option
                (onSelectionChange)="selectOrder($event)"
                [value]="null"
              >
                {{ 'done.table.filters.all' | transloco }}
              </mat-option>
              @for (option of sections; track option) {
                <mat-option
                  (onSelectionChange)="selectOrder($event)"
                  [value]="option"
                  >{{ option }}</mat-option
                >
              }
            </mat-select>
          </mat-form-field>
        </ng-container>
      }
    </div>
    @if (!!selection.selected.length) {
      <div class="action-button">
        <span class="selected-orders">
          {{
            'done.table.actions.selected'
              | transloco: { count: selection.selected.length }
          }}
        </span>
        <div
          [matTooltip]="
            !selection.selected.length
              ? ('done.table.tool-tip' | transloco)
              : ''
          "
        >
          <button
            mat-icon-button
            [disabled]="!selection.selected.length"
            class="undo-done-orders"
            (click)="undoSelectedOrders()"
          >
            <mat-icon>undo</mat-icon>
          </button>
        </div>
        <div
          [matTooltip]="
            !selection.selected.length
              ? ('done.table.tool-tip' | transloco)
              : ''
          "
        >
          <button
            mat-icon-button
            [disabled]="!selection.selected.length"
            [matMenuTriggerFor]="deleteMenu"
          >
            @if (selection.selected.length <= 1) {
              <mat-icon>delete</mat-icon>
            }
            @if (selection.selected.length > 1) {
              <mat-icon>delete_sweep</mat-icon>
            }
          </button>
          <mat-menu #deleteMenu>
            <span class="delete-menu-label" mat-menu-item>
              {{ 'done.table.actions.are-you-sure' | transloco }}</span
            >
            <button
              mat-menu-item
              class="delete-menu-button"
              (click)="deleteSelectedOrders()"
            >
              @if (selection.selected.length <= 1) {
                <mat-icon>delete</mat-icon>
              }
              @if (selection.selected.length > 1) {
                <mat-icon>delete_sweep</mat-icon>
              }
              {{
                selection.selected.length <= 1
                  ? ('done.table.actions.delete' | transloco)
                  : ('done.table.actions.delete-all' | transloco)
              }}
            </button>
          </mat-menu>
        </div>
      </div>
    }
  </div>
  <mat-divider></mat-divider>
  @if (!loadingSpinner && itemsCount === 0) {
    <div class="empty-table">
      <div class="mat-h2 no-items">
        {{ 'shared.tables.zzz' | transloco }}
      </div>
      <div>{{ 'shared.tables.empty' | transloco }}</div>
    </div>
  } @else {
    @if (dataSourceToDisplay !== null && !loadingSpinner) {
      <table
        mat-table
        #table
        class="data-table mobile-support"
        [dataSource]="dataSourceToDisplay"
      >
        <ng-container matColumnDef="select">
          <mat-header-cell class="select-column header" *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell class="select-column" *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell class="consumer-column" *matHeaderCellDef
            ><div [innerHTML]="'done.table.headers.name' | transloco"></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="consumer-column">
            <span class="consumer-name">
              <span>
                {{ element.name }}
                @if (element.room) {
                  ({{ element.room }})
                }
              </span>
              @if (element.vip) {
                <mat-icon class="vip">local_activity</mat-icon>
              }
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="time">
          <mat-header-cell
            class="header completion-time-column"
            *matHeaderCellDef
            ><div
              [innerHTML]="'done.table.headers.completion-time' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="completion-time completion-time-column"
            [class.completion-time-vip]="element.vip"
          >
            <resto-fired-waiting-time
              [baseTime]="element.service_done_time"
              [time]="element.service_fired_time"
              [clock]="null"
            ></resto-fired-waiting-time>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="order">
          <mat-header-cell class="header order-item-column" *matHeaderCellDef
            ><div [innerHTML]="'done.table.headers.order' | transloco"></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="order-item-column">
            @if (element.is_special_order) {
              <div class="special-order">
                {{ 'done.table.special-order' | transloco }}
              </div>
            }
            <resto-order-item
              [quantity]="element.quantity"
              [texture]="element.texture"
              [consistency]="element.consistency"
              [portionSize]="element.portion_size"
              [item]="element.item"
              [itemOption]="element.item_option"
              [description]="element.description"
              [variants]="element.variants"
              [diets]="element.order_diets"
              [description]="element.description"
              (updateComment)="
                updateComment.emit({
                  id: element.ids.slice(-1)[0],
                  comment: $event,
                })
              "
            ></resto-order-item>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="filteredColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: filteredColumns"
          [class.row-selected]="selectedRowId === row.id"
          (click)="selectRow(row)"
        ></mat-row>
        <ng-container matColumnDef="groupHeader">
          <mat-cell mat-cell *matCellDef="let groupBy">
            <mat-checkbox
              class="group-header-checkbox"
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="
                $event ? selectTableOrders(groupBy.table_number, $event) : null
              "
              [checked]="checkTableHeader(groupBy.table_number)"
            >
            </mat-checkbox>
            <span class="header-text"
              >{{ 'done.table.headers.table' | transloco }}&nbsp;
              <strong>{{
                groupBy.table_number
                  ? groupBy.table_number
                  : ('done.table.headers.unassigned' | transloco)
              }}</strong></span
            >
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell
            class="header template-cell column-type"
            *matHeaderCellDef
            ><div
              [innerHTML]="'reservations.table.headers.type' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="template-cell column-type">
            {{ element.type_name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="diets">
          <mat-header-cell
            class="header template-cell column-diets"
            *matHeaderCellDef
            ><div
              [innerHTML]="'reservations.table.headers.diet' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="template-cell column-diets"
          >
            <span class="diet-names">
              @if (element.diets?.length > 0) {
                @for (diet of element.diets; track diet) {
                  <span>{{ diet }}</span>
                }
              } @else {
                <span>-</span>
              }
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="profile">
          <mat-header-cell
            class="header template-cell comment-cell"
            *matHeaderCellDef
            ><div
              [innerHTML]="'reservations.table.headers.comments' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="template-cell comment-cell"
          >
            @if (
              element?.custom_data &&
              (element.custom_data | keyvalue).length > 0
            ) {
              <span>
                @for (item of element?.custom_data | keyvalue; track item) {
                  <resto-consumer-data-item
                    class="item"
                    [label]="item.key"
                    [value]="item.value"
                  ></resto-consumer-data-item>
                }
              </span>
            } @else {
              <span>-</span>
            }
          </mat-cell>
        </ng-container>
        <mat-row
          mat-row
          *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
          class="group-header"
        ></mat-row>
      </table>
    }
  }
  @if (loadingSpinner) {
    <div class="spinner-container">
      <mat-spinner class="loading-spinner" mode="indeterminate"></mat-spinner>
    </div>
  }
</div>
