<div
  class="wrapper mat-elevation-z4"
  [class.no-mobile]="selectedViewFilter === 1 && !loadingSpinner && itemsCount"
>
  <div class="table">
    <div class="filters">
      <mat-button-toggle-group class="toggle-buttons" [value]="1">
        <mat-button-toggle
          class="toggle-button"
          [class.selected]="selectedViewFilter === 1"
          [value]="1"
          (change)="setViewFilter(1)"
        >
          <mat-icon>format_list_bulleted</mat-icon>
          {{ 'reservations.table.filters.list-view' | transloco }}
        </mat-button-toggle>
        <mat-button-toggle
          class="toggle-button"
          [class.selected]="selectedViewFilter === 2"
          [value]="2"
          (change)="setViewFilter(2)"
        >
          <mat-icon>sensors</mat-icon>
          {{ 'reservations.table.filters.rfid-scan' | transloco }}
        </mat-button-toggle>
      </mat-button-toggle-group>
      @if (selectedViewFilter === 1) {
        <div class="table-filter">
          @if (isRequireTableSeating && !applyTableFilterReservations) {
            <resto-filter-tags
              [tags]="tables"
              (changeSelection)="filterTables($event)"
            ></resto-filter-tags>
          }
          @if (
            !hideCheckedinReservations &&
            ((reservationConsumers && reservationConsumers.length > 0) ||
              filterCheckinOnly)
          ) {
            <mat-checkbox
              class="checked-in-consumers"
              color="primary"
              [ngModel]="filterCheckinOnly"
              (change)="hideCheckedInConsumers($event)"
            >
              {{
                'reservations.table.actions.hide-checked-in-consumers'
                  | transloco
              }}
            </mat-checkbox>
          }
        </div>
      }
      @if (!!selection.selected.length && selectedViewFilter === 1) {
        <div class="action-button">
          <span class="selected-consumers">
            {{
              'reservations.table.actions.selected'
                | transloco: { count: selection.selected.length }
            }}
          </span>
          <div
            [matTooltip]="
              !selection.selected.length
                ? ('reservations.table.tool-tip' | transloco)
                : ''
            "
          >
            <button
              [matMenuTriggerFor]="
                isRequireTableSeating ? multipleConsumerTableInput : null
              "
              [disabled]="!selection.selected.length"
              class="assign-table"
              mat-flat-button
              color="primary"
              (click)="
                !isRequireTableSeating
                  ? changeMultipleReservationStatus()
                  : null
              "
            >
              <ng-container>
                {{
                  isRequireTableSeating
                    ? ('reservations.table.actions.assign-to-table' | transloco)
                    : ('reservations.table.actions.confirm-reservations'
                      | transloco)
                }}
                <mat-icon class="table-icon">table_bar</mat-icon>
              </ng-container>
            </button>
            <mat-menu #multipleConsumerTableInput>
              <mat-form-field class="table-input" subscriptSizing="dynamic">
                <input
                  (click)="$event.stopPropagation()"
                  matInput
                  type="text"
                  name="table_number_multiple"
                  [placeholder]="'reservations.table.table-number' | transloco"
                  [formControl]="tableNumber"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="changeMultipleReservationStatus()"
                >
                  <mat-icon>check</mat-icon>
                </button>
              </mat-form-field>
            </mat-menu>
          </div>
          <span
            [matTooltip]="
              !selection.selected.length
                ? ('reservations.table.tool-tip' | transloco)
                : ''
            "
          >
            <button
              mat-icon-button
              class="delete-options"
              [matMenuTriggerFor]="deleteMenu"
              [disabled]="!selection.selected.length"
            >
              @if (selection.selected.length <= 1) {
                <mat-icon>delete</mat-icon>
              }
              @if (selection.selected.length > 1) {
                <mat-icon>delete_sweep</mat-icon>
              }
            </button>
          </span>
          <mat-divider></mat-divider>
          <mat-menu #deleteMenu>
            <span class="delete-menu-label" mat-menu-item>
              {{ 'reservations.table.actions.are-you-sure' | transloco }}</span
            >
            <button
              mat-menu-item
              class="delete-menu-button"
              (click)="deleteSelectedReservations()"
            >
              @if (selection.selected.length <= 1) {
                <mat-icon>delete</mat-icon>
              }
              @if (selection.selected.length > 1) {
                <mat-icon>delete_sweep</mat-icon>
              }
              {{
                selection.selected.length <= 1
                  ? ('reservations.table.actions.delete' | transloco)
                  : ('reservations.table.actions.delete-all' | transloco)
              }}
            </button>
          </mat-menu>
        </div>
      }
    </div>
    <mat-divider></mat-divider>

    @if (selectedViewFilter === 1) {
      @if (loadingSpinner) {
        <div class="spinner-container">
          <mat-spinner
            class="loading-spinner"
            mode="indeterminate"
          ></mat-spinner>
        </div>
      }
      @if (!loadingSpinner && itemsCount === 0) {
        <div class="empty-table">
          <div class="mat-h2 no-items">
            {{ 'shared.tables.zzz' | transloco }}
          </div>
          <div>{{ 'shared.tables.empty' | transloco }}</div>
        </div>
      } @else {
        @if (
          reservationConsumers !== null &&
          !loadingSpinner &&
          selectedViewFilter === 1
        ) {
          <table
            mat-table
            #table
            class="data-table"
            [dataSource]="reservations"
          >
            <ng-container matColumnDef="select">
              <mat-header-cell class="select-column header" *matHeaderCellDef>
                <mat-checkbox
                  color="primary"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell class="select-column" *matCellDef="let row">
                <mat-checkbox
                  color="primary"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell class="header" *matHeaderCellDef
                ><div
                  [innerHTML]="'reservations.table.headers.name' | transloco"
                ></div>
                <resto-sort-button
                  class="sort-button"
                  key="name"
                  [currentKey]="ordering"
                  (changeSorting)="sort($event)"
                ></resto-sort-button>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="small-text">
                <span class="consumer-name">
                  @if (element.vip) {
                    <mat-icon class="vip">local_activity</mat-icon>
                  }
                  {{ element.name }}</span
                >
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="room">
              <mat-header-cell class="header template-cell" *matHeaderCellDef
                ><div
                  [innerHTML]="'reservations.table.headers.room' | transloco"
                ></div>
                <resto-sort-button
                  class="sort-button"
                  key="room"
                  [currentKey]="ordering"
                  (changeSorting)="sort($event)"
                ></resto-sort-button>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="template-cell">
                {{ element.room }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="reservation_status">
              <mat-header-cell class="header template-cell" *matHeaderCellDef
                ><div
                  [innerHTML]="
                    'reservations.table.headers.reservation-status' | transloco
                  "
                ></div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="template-cell">
                <button
                  [matMenuTriggerFor]="
                    isRequireTableSeating || element.service_status
                      ? tableInput
                      : null
                  "
                  mat-fab
                  extended
                  color="primary"
                  class="reservation-status"
                  [ngClass]="getClassName(element.service_status, element.vip)"
                  (click)="setCurrentConsumer(element)"
                >
                  <mat-icon
                    class="send-icon"
                    [ngClass]="
                      getIconClassName(element.service_status, element.vip)
                    "
                    >send</mat-icon
                  >
                  @if (element.service_status) {
                    <mat-icon
                      class="check-icon"
                      [ngClass]="
                        getIconClassName(element.service_status, element.vip)
                      "
                      >check</mat-icon
                    >
                  }
                </button>
              </mat-cell>
            </ng-container>
            <mat-menu #tableInput>
              @if (isRequireTableSeating) {
                <mat-form-field class="table-input" subscriptSizing="dynamic">
                  <input
                    (click)="$event.stopPropagation()"
                    matInput
                    type="text"
                    name="table_number"
                    [placeholder]="
                      'reservations.table.table-number' | transloco
                    "
                    [formControl]="tableNumber"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="changeReservationStatus()"
                  >
                    <mat-icon>check</mat-icon>
                  </button>
                </mat-form-field>
              }
              @if (currentConsumer && currentConsumer.service_status) {
                <button
                  mat-flat-button
                  [ngClass]="
                    !isRequireTableSeating
                      ? 'clear-button'
                      : 'clear-reservation'
                  "
                  (click)="clearReservation()"
                >
                  <mat-icon>delete</mat-icon>
                  <span class="mat-body-2">{{
                    'reservations.table.actions.clear-reservation' | transloco
                  }}</span>
                </button>
              }
            </mat-menu>
            <ng-container matColumnDef="table">
              <mat-header-cell class="header template-cell" *matHeaderCellDef
                ><div
                  [innerHTML]="'reservations.table.headers.table' | transloco"
                ></div>
                <resto-sort-button
                  class="sort-button"
                  key="table_number"
                  [currentKey]="ordering"
                  (changeSorting)="sort($event)"
                ></resto-sort-button>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="template-cell">
                @if (ordertakingTableFeature && !element.service_status_table) {
                  <span class="saved-table">
                    {{ element.table_number ?? '-' }}
                  </span>
                } @else {
                  {{ element.service_status_table ?? '-' }}
                  @if (
                    ordertakingTableFeature &&
                    element.service_status_table !== element.table_number
                  ) {
                    <button
                      mat-icon-button
                      [matTooltip]="
                        'reservations.table.actions.save-favourite' | transloco
                      "
                      (click)="saveTable(element)"
                    >
                      <mat-icon>download</mat-icon>
                    </button>
                  }
                }
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell class="header template-cell" *matHeaderCellDef
                ><div
                  [innerHTML]="'reservations.table.headers.type' | transloco"
                ></div>
                <resto-sort-button
                  class="sort-button"
                  key="type"
                  [currentKey]="ordering"
                  (changeSorting)="sort($event)"
                ></resto-sort-button>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="template-cell">
                {{ element.type_name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="diets">
              <mat-header-cell class="header template-cell" *matHeaderCellDef
                ><div
                  [innerHTML]="'reservations.table.headers.diet' | transloco"
                ></div>
                <resto-sort-button
                  class="sort-button"
                  key="diets_detail"
                  [currentKey]="ordering"
                  (changeSorting)="sort($event)"
                ></resto-sort-button>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="template-cell">
                <span class="diet-names">
                  @if (element.diets_detail?.length > 0) {
                    @for (diet of element.diets_detail; track diet) {
                      <span>{{ diet.diet_name }}</span>
                    }
                  } @else {
                    <span>-</span>
                  }
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="profile">
              <mat-header-cell
                class="header template-cell comment-cell"
                *matHeaderCellDef
                ><div
                  [innerHTML]="
                    'reservations.table.headers.comments' | transloco
                  "
                ></div>
                <resto-sort-button
                  class="sort-button"
                  key="comments"
                  [currentKey]="ordering"
                  (changeSorting)="sort($event)"
                ></resto-sort-button>
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                class="template-cell comment-cell"
              >
                @if (
                  element?.custom_data &&
                  (element.custom_data | keyvalue).length > 0
                ) {
                  <span>
                    @for (item of element?.custom_data | keyvalue; track item) {
                      <resto-consumer-data-item
                        class="item"
                        [label]="item.key"
                        [value]="item.value"
                      ></resto-consumer-data-item>
                    }
                  </span>
                } @else {
                  <span>-</span>
                }
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="columnsToDisplay; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: columnsToDisplay"
              [class.row-selected]="selectedRowId === row.id"
              (click)="selectRow(row)"
            ></mat-row>
          </table>
        }
        <mat-divider></mat-divider>
        @if (loadingSpinner === false && selectedViewFilter === 1) {
          <mat-paginator
            class="paginator"
            #paginator
            [length]="itemsCount"
            [pageIndex]="currentPageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 20, 50]"
            (page)="handlePaginator($event)"
          >
          </mat-paginator>
        }
      }
    } @else {
      <resto-reservations-rfid
        [prefillSavedTable]="prefillSavedTable"
        [isRfidDetailsLoading]="isRfidDetailsLoading"
        [isRequireTableSeating]="isRequireTableSeating"
        [ordertakingTableFeature]="ordertakingTableFeature"
        [prefillLastUsedTable]="prefillLastUsedTable"
        [previousTableNumber]="previousTableNumber"
        [rfidDetails]="rfidDetails"
        [rfidDetailsCount]="rfidDetailsCount"
        [showThankYouMessage]="showThankYouMessage"
        (changeReservationStatusEvent)="
          changeReservationStatusEvent.emit($event)
        "
        (clearRfidFieldEvent)="clearRfidFieldEvent.emit()"
        (fetchRfidDetailEvent)="fetchRfidDetailEvent.emit($event)"
        (patchConsumer)="patchConsumer.emit($event)"
        (setPreviousTableNumberEvent)="setPreviousTableNumberEvent.emit($event)"
      ></resto-reservations-rfid>
    }
  </div>
</div>
