import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Order } from 'src/app/models/orders';

@Component({
  selector: 'resto-fired-count-down',
  templateUrl: './fired-count-down.component.html',
  styleUrls: ['./fired-count-down.component.scss'],
  standalone: true,
})
export class FiredCountDownComponent implements OnChanges {
  @Input() order!: Order;
  @Input() selectedReason!: string;
  @Input() showCountDown!: boolean;
  @Input() startCountDown!: boolean;

  @Output() countDownComplete = new EventEmitter<{
    id: number;
    selectedReason: string;
  }>();
  @Output() expediteOrder = new EventEmitter<{
    ids: number[];
    selectedReason: string;
  }>();

  DURATION = 3;
  subscribeTimer: number = 0;
  timeLeft: number = this.DURATION;
  countDownTime!: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'showCountDown' in changes &&
      this.showCountDown &&
      this.selectedReason
    ) {
      this.startTimer(this.selectedReason);
    }
  }

  startTimer(selectedReason: string): void {
    this.countDownTime?.unsubscribe();
    this.subscribeTimer = 0;
    const source = timer(1000, 1000);
    this.countDownTime = source.subscribe((val: number) => {
      if (val < this.DURATION) {
        this.subscribeTimer = this.timeLeft - val;
      } else {
        this.countDownTime.unsubscribe();
        this.expediteOrder.emit({ ids: this.order.ids, selectedReason });
        this.countDownComplete.emit({ id: this.order.id, selectedReason });
      }
    });
  }
}
