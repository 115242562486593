import { Injectable } from '@angular/core';
import { LoginSuccess, User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  saveToken = (data: LoginSuccess) => {
    this.saveAccessToken(data?.access);
    localStorage.setItem(environment.refreshTokenKey, data.refresh);
  };

  saveAccessToken = (access: string, refresh?: string) => {
    localStorage.setItem(environment.accessTokenKey, access);
    if (refresh) localStorage.setItem(environment.refreshTokenKey, refresh);
  };

  removeToken = () => {
    localStorage.clear();
  };

  removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
  };

  getToken = (): string | undefined | null => {
    if (localStorage) return localStorage.getItem(environment.accessTokenKey);
    return null;
  };

  getStoredUser = (): User | null => {
    const userString = localStorage.getItem('user');
    if (!userString) return null;
    return JSON.parse(userString) as User;
  };

  saveUserLocally = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
  };
}
