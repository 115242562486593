import { Action, createReducer, on } from '@ngrx/store';
import { ServiceSettings, User } from 'src/app/models/user';
import * as UserAction from './user.actions';

export interface UserState {
  currentUser: User | null;
  isLogged: boolean | null;
  loading: boolean;
  services: string[];
  sections: string[];
  availableSectionsLoading: boolean;
  sectionsDisplayed: string[] | null;
  service_settings: ServiceSettings | null;
  current_service: string | undefined;
  current_date: string | undefined;
  previous_table_number: string;
  tables: string[];
}

export const initialState: UserState = {
  currentUser: null,
  isLogged: null,
  loading: false,
  services: [],
  sections: [],
  availableSectionsLoading: true,
  sectionsDisplayed: [],
  service_settings: null,
  current_service: undefined,
  current_date: undefined,
  previous_table_number: '',
  tables: [],
};

const _currentUserReducer = createReducer(
  initialState,
  on(UserAction.setLogin, (state, { isLogged }) => ({ ...state, isLogged })),
  on(UserAction.setUser, (state, { payload }) => ({
    ...state,
    currentUser: payload,
  })),
  on(UserAction.setLoginSpinner, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(UserAction.setServicesList, (state, { services }) => ({
    ...state,
    services,
  })),
  on(UserAction.setAvailableSections, (state, { sections }) => ({
    ...state,
    sections,
  })),
  on(
    UserAction.setAvailableSectionsLoading,
    (state, { availableSectionsLoading }) => ({
      ...state,
      availableSectionsLoading,
    }),
  ),
  on(UserAction.setSectionsDisplayed, (state, { sections }) => ({
    ...state,
    sectionsDisplayed: sections,
  })),
  on(UserAction.saveServiceSettings, (state, { serviceSettings }) => ({
    ...state,
    service_settings: {
      ...((state.service_settings as ServiceSettings) ?? {}),
      ...serviceSettings,
    },
  })),
  on(UserAction.setCurrentService, (state, { service }) => ({
    ...state,
    current_service: service,
  })),
  on(UserAction.setCurrentDate, (state, { date }) => ({
    ...state,
    current_date: date,
  })),
  on(UserAction.setPreviousTableNumber, (state, { tableNumber }) => ({
    ...state,
    previous_table_number: tableNumber,
  })),
  on(UserAction.setTables, (state, { tables }) => ({ ...state, tables })),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return _currentUserReducer(state, action);
}
