import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  selectCurrentService,
  selectHasOrdertakingTableFeature,
  selectImmedateDone,
  selectIsLogged,
  selectTables,
  selectUser,
} from 'src/app/reducers/user/user.selectors';

import { IState } from './reducers';
import {
  selectTotalDoneOrdersCount,
  selectTotalFiredOrdersCount,
  selectTotalOrdersCount,
} from './reducers/orders/orders.selectors';
import { selectTotalReservationsCount } from './reducers/reservations/reservations.selectors';
import {
  selectIsServiceUser,
  selectServiceList,
  selectServiceSettings,
  selectUserLocation,
} from './reducers/user/user.selectors';
import { selectGlobalError } from './reducers/shared/shared.selectors';
import { SettingsComponent } from './global/settings/settings.component';
import { HeaderComponent } from './global/header/header.component';
import { NavigationFiltersComponent } from './global/navigation-filters/navigation-filters.component';
import { FooterComponent } from './global/footer/footer.component';
import { ErrorBannerComponent } from './global/error-banner/error-banner.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'resto-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    ErrorBannerComponent,
    FooterComponent,
    HeaderComponent,
    NavigationFiltersComponent,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    NgClass,
    RouterOutlet,
    SettingsComponent,
  ],
})
export class AppComponent {
  availableServices$ = this.ngrxStore.select(selectServiceList);
  currentService$ = this.ngrxStore.select(selectCurrentService);
  hasOrdertakingTableFeature$ = this.ngrxStore.select(
    selectHasOrdertakingTableFeature,
  );
  immediateDone$ = this.ngrxStore.select(selectImmedateDone);
  isLoggedIn$ = this.ngrxStore.select(selectIsLogged);
  isServiceUser$ = this.ngrxStore.select(selectIsServiceUser);
  ordersCount$ = this.ngrxStore.select(selectTotalOrdersCount);
  reservationsCount$ = this.ngrxStore.select(selectTotalReservationsCount);
  firedOrdersCount$ = this.ngrxStore.select(selectTotalFiredOrdersCount);
  doneOrdersCount$ = this.ngrxStore.select(selectTotalDoneOrdersCount);
  serviceSettings$ = this.ngrxStore.select(selectServiceSettings);
  user$ = this.ngrxStore.select(selectUser);
  userLocation$ = this.ngrxStore.select(selectUserLocation);
  tables$ = this.ngrxStore.select(selectTables);
  globalError$ = this.ngrxStore.select(selectGlobalError);

  constructor(private ngrxStore: Store<IState>) {}
}
