import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Order } from 'src/app/models/orders';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'resto-order-fired-button',
  templateUrl: './order-fired-button.component.html',
  styleUrls: ['./order-fired-button.component.scss'],
  standalone: true,
  imports: [MatButtonModule, NgClass, MatIconModule],
})
export class OrderFiredButtonComponent implements OnChanges {
  @Input() disableButton!: boolean;
  @Input() fireAll!: boolean;
  @Input() order!: Order;
  @Input() unFireAll!: boolean;
  @Input() unFireOrderId!: number;

  @Output() countDownComplete = new EventEmitter<{ id: number }>();
  @Output() countDownStart = new EventEmitter<{ id: number }>();
  @Output() fireOrder = new EventEmitter<{ ids: number[] }>();

  DURATION = 3;
  subscribeTimer: number = 0;
  timeLeft: number = this.DURATION;
  countDownTime!: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disableButton && this.fireAll && this.timeLeft === 3) {
      this.startTimer();
    }
    if (
      'unFireOrderId' in changes &&
      this.unFireOrderId &&
      this.order.id == this.unFireOrderId
    ) {
      this.countDownTime?.unsubscribe();
      this.countDownComplete.emit({ id: this.unFireOrderId });
    }

    if ('unFireAll' in changes && this.unFireAll) {
      this.countDownTime?.unsubscribe();
    }
  }

  startTimer(): void {
    this.countDownTime?.unsubscribe();
    this.subscribeTimer = 0;
    this.fireAll = false;
    this.countDownStart.emit({ id: this.order.id });
    const source = timer(1000, 1000);
    this.countDownTime = source.subscribe((val: number) => {
      if (val < this.DURATION) {
        this.subscribeTimer = this.timeLeft - val;
      } else {
        this.countDownTime.unsubscribe();
        this.fireOrder.emit({ ids: this.order.ids });
        this.countDownComplete.emit({ id: this.order.id });
      }
    });
  }
}
