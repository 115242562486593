<resto-orders-table
  [isRequireTableSeating]="isRequireTableSeating$ | async"
  [itemsCount]="totalOrdersCount$ | async"
  [loadingSpinner]="isTableDataLoading$ | async"
  [orders]="orders$ | async"
  [selectedColumns]="selectedColumns$ | async"
  [sectionFilter]="sectionFilter$ | async"
  [sections]="availableSections$ | async"
  [tablesToShow]="tablesToShow$ | async"
  (addOrderEvent)="addOrder($event)"
  (applyParams)="retrieveOrders($event)"
  (changeOrderTableEvent)="changeOrderTable($event)"
  (deleteOrder)="deleteOrder($event)"
  (doneOrder)="doneOrder($event)"
  (fireOrder)="fireOrder($event)"
  (filterBySection)="filterBySection($event)"
  (undoOrder)="undoOrder($event)"
  (updateComment)="updateComment($event)"
></resto-orders-table>
