import { Component, Input } from '@angular/core';
import { Primitive } from 'src/app/models/generics';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'resto-consumer-data-item',
  templateUrl: './consumer-data-item.component.html',
  styleUrls: ['./consumer-data-item.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class ConsumerDataItemComponent {
  @Input() showLabel = true;
  @Input() label!: any; // Primitive;
  @Input() value!: any; // Primitive;
  @Input() valueOverwritten!: any; // Primitive;
}
