import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'resto-fired-waiting-time',
  templateUrl: './fired-waiting-time.component.html',
  styleUrls: ['./fired-waiting-time.component.scss'],
  standalone: true,
  imports: [TranslocoPipe],
})
export class FiredWaitingTimeComponent implements OnChanges {
  @Input() clock: Date | null = null;
  @Input() time!: string;
  @Input() baseTime!: string;

  timeDuration: number | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if ('clock' in changes && this.time) {
      this.setTimeDuration();
    }
  }

  setTimeDuration(): void {
    this.timeDuration = this.getTimeDiffToNow(this.time);
  }

  getTimeDiffToNow(startDate: string | null = null): number {
    const startTime = new Date(startDate || '');
    const now = this.baseTime ? new Date(this.baseTime) : new Date();
    const difference = now.getTime() - startTime.getTime();
    return Math.round(difference / 60000);
  }
}
