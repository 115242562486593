import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import {
  MatOptionSelectionChange,
  MatOptionModule,
} from '@angular/material/core';
import { uniq } from 'lodash-es';
import {
  AddOrderParams,
  GroupBy,
  GroupedTickets,
  Order,
  ServiceStatus,
} from 'src/app/models/orders';
import { calculateTotalOrders } from 'src/app/shared/utils.functions';
import { ReservationDetail } from 'src/app/models/reservations';
import { OrderTableBaseComponent } from 'src/app/shared/Classes/order-table-component.base';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderItemComponent } from '../../../shared/components/order-item/order-item.component';
import { OrderFiredButtonComponent } from './order-fired-button/order-fired-button.component';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KeyValuePipe } from '@angular/common';
import { ConsumerDataItemComponent } from 'src/app/shared/components/consumer-data-item/consumer-data-item.component';

@Component({
  selector: 'resto-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: [
    './orders-table.component.scss',
    './../../../shared/styles/table-component.base.scss',
  ],
  standalone: true,
  imports: [
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatCheckboxModule,
    OrderFiredButtonComponent,
    OrderItemComponent,
    MatProgressSpinnerModule,
    TranslocoPipe,
    KeyValuePipe,
    ConsumerDataItemComponent,
  ],
})
export class OrdersTableComponent
  extends OrderTableBaseComponent
  implements OnChanges
{
  @Output() doneOrder = new EventEmitter<{ ids: number[] }>();
  @Input() isRequireTableSeating!: boolean | null | undefined;
  @Input() orders!: GroupedTickets[] | null;
  @Input() selectedColumns!: string[] | null;
  @Input() sectionFilter!: string | null;
  @Input() sections!: string[] | null;
  @Input() tablesToShow!: string[] | null | undefined;

  @Output() addOrderEvent = new EventEmitter<{
    data: { service_status: ServiceStatus } & Partial<AddOrderParams>;
  }>();
  @Output() changeOrderTableEvent = new EventEmitter<{
    data: Partial<ReservationDetail>;
    callback?: boolean;
  }>();
  @Output() fireOrder = new EventEmitter<{ ids: number[] }>();
  @Output() undoOrder = new EventEmitter<{
    data: Partial<ReservationDetail>;
  }>();
  @Output() updateComment = new EventEmitter<{
    id: number;
    comment: string;
  }>();

  @ViewChild(MatMenuTrigger) addOrder!: MatMenuTrigger;

  allColumns = [
    'select',
    'name',
    'order_status',
    'order',
    'type',
    'diets',
    'profile',
  ];
  columnsToDisplay: string[] = [];
  filteredColumns: string[] = [];
  headers = ['select', 'name', 'order_status', 'order'];
  dataSource!: Order[];
  dataSourceToDisplay!: Order[];
  fireAll: boolean = false;
  orderDetail = new FormControl('', Validators.required);
  ordersBeingFired = new Set<number>();
  selectOrderFormControl = new FormControl('');
  selectSectionFormControl = new FormControl<string | null>(
    null,
    Validators.required,
  );
  tableNumber = new FormControl('');
  unFireAll: boolean = false;
  unFireOrderId!: number;

  override ngOnChanges(changes: SimpleChanges): void {
    if ('selectedColumns' in changes && this.selectedColumns) {
      const selectedColumns = this.selectedColumns.filter((column) =>
        ['type', 'diets', 'profile'].includes(column),
      );
      this.filteredColumns = [];
      this.filteredColumns = this.headers.concat(selectedColumns);
      this.columnsToDisplay = this.allColumns.filter((o) =>
        this.filteredColumns.some((j) => o === j),
      );
    }
    if (changes['orders'] && this.orders) {
      this.dataSource = this.orders.reduce(
        (acc, { table_number, orders }) => [
          ...acc,
          { table_number, isGroupBy: true } as GroupBy,
          ...orders,
        ],
        [] as Order[],
      );
      this.selection.clear();
    }
    if (
      'sectionFilter' in changes &&
      this.selectOrderFormControl.value !== this.sectionFilter
    ) {
      this.selectOrderFormControl.setValue(this.sectionFilter, {
        emitEvent: false,
      });
    }
    if (changes && changes['lang']) this.ordering = this.lang;
    if (changes['tablesToShow'] || (changes['orders'] && this.orders)) {
      if (this.tablesToShow?.length) {
        this.dataSourceToDisplay = this.dataSource.filter((o) =>
          this.tablesToShow!.some(
            (tableNumber) =>
              o.table_number === tableNumber ||
              o.service_table_number === tableNumber,
          ),
        );
      } else {
        this.dataSourceToDisplay = this.dataSource;
      }
    }
  }

  doneAllOrders(): void {
    const ids: number[] =
      this.selection.selected?.map((order) => order.ids).flat(1) ?? [];
    this.doneOrder.emit({ ids });
    this.selection.clear();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.reduce(function (
      previousValue: number,
      currentObject: Order,
    ) {
      return previousValue + (currentObject.id ? 1 : 0);
    }, 0);
    const numRows = calculateTotalOrders(this.orders);
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.orders?.forEach((row) =>
          row.orders.forEach((order: Order) => this.selection.select(order)),
        );
  }

  selectTableOrders(tableNumber: string, event: MatCheckboxChange): void {
    this.orders?.forEach((el) => {
      if (el.table_number === tableNumber) {
        if (event.checked) {
          el.orders.forEach((order: Order) => this.selection.select(order));
        } else {
          el.orders.forEach((order: Order) => this.selection.deselect(order));
        }
      }
    });
  }

  checkTableHeader(tableNumber: string): boolean {
    let checked: boolean = false;
    let selectedTableOrder = 0;
    this.orders?.forEach((el) => {
      if (el.table_number === tableNumber) {
        el.orders.forEach((order: Order) => {
          if (this.selection.isSelected(order)) {
            return (selectedTableOrder += 1);
          } else {
            return;
          }
        });
      }
    });
    this.orders?.forEach((el) => {
      if (
        el.table_number === tableNumber &&
        el.orders.length === selectedTableOrder
      ) {
        checked = true;
      }
    });
    return checked;
  }

  multipleConsumerTableUpdate(): void {
    const selectedConsumers = this.selection.selected
      .filter((order: Order) => !order.is_user)
      .map((order: Order) => order.consumer);
    const selectedUsers = this.selection.selected
      .filter((order: Order) => order.is_user)
      .map((order: Order) => order.created_by);
    const data: Partial<ReservationDetail> = {
      consumers: [...new Set(selectedConsumers)],
      users: [...new Set(selectedUsers)],
    };
    if (this.isRequireTableSeating) {
      data.table_number = this.tableNumber.value ?? '';
    }
    this.changeOrderTableEvent.emit({ data });
    this.tableNumber.reset();
    this.selection.clear();
  }

  addSpecialOrder(): void {
    this.selection.selected.forEach((el) => {
      if (
        this.orderDetail.value &&
        this.selectSectionFormControl.value !== null
      ) {
        const data: {
          service_status: ServiceStatus;
        } & Partial<AddOrderParams> = {
          item: this.orderDetail.value,
          item_baselang: this.orderDetail.value,
          section_sorting: el.section_sorting,
          section_level3: this.selectSectionFormControl.value,
          section_level3_baselang: this.selectSectionFormControl.value,
          service_status: 2,
        };
        el.is_user
          ? (data.created_by = el.created_by)
          : (data.consumer = el.consumer);
        this.addOrderEvent.emit({ data });
      }
    });
    this.orderDetail.reset();
    this.selectSectionFormControl.reset();
    this.selection.clear();
    this.addOrder.closeMenu();
  }

  selectSection(
    event: MatOptionSelectionChange<string> | MatOptionSelectionChange<null>,
  ): void {
    if (event && event.isUserInput) {
      this.selectSectionFormControl.patchValue(event.source.value);
    }
  }

  countDownStart(data: { id: number }): void {
    this.unFireAll = false;
    this.unFireOrderId = 0;
    this.ordersBeingFired.add(data.id);
  }

  countDownComplete(data: { id: number }): void {
    this.ordersBeingFired.delete(data.id);
  }

  unFireOrder(id: number): void {
    this.unFireOrderId = id;
    this.ordersBeingFired.delete(id);
  }

  unFireAllOrders(): void {
    this.unFireAll = true;
    this.selection.selected.forEach((el) => (this.unFireOrderId = el.id));
    this.ordersBeingFired.clear();
  }

  fireAllOrders(): void {
    this.selection.selected.forEach((el) => {
      this.ordersBeingFired.add(el.id);
    });
    this.fireAll = true;
    this.unFireAll = false;
    this.selection.clear();
  }

  fireOrderEvent({ ids }: { ids: number[] }): void {
    this.fireOrder.emit({ ids });
  }

  undoSelectedOrders(): void {
    const selectedConsumers = this.selection.selected
      .filter((order: Order) => !order.is_user)
      .map((order: Order) => order.consumer);
    const selectedUsers = this.selection.selected
      .filter((order: Order) => order.is_user)
      .map((order: Order) => order.created_by);
    const data: Partial<ReservationDetail> = {
      consumers: uniq(selectedConsumers),
      users: uniq(selectedUsers),
    };
    this.undoOrder.emit({ data });
    this.selection.clear();
  }

  trackByFn(index: number, item: Order): number {
    return item.id;
  }
}
