import { createAction, props } from '@ngrx/store';
import { LoginForm, LoginState } from 'src/app/models/authentication';
import { ServiceSettings, User } from 'src/app/models/user';

export const clearUser = createAction(`[User] Clear User`);

export const loginUser = createAction(
  `[Login] Attempt to login with LoginForm data`,
  props<LoginForm>(),
);

export const setLogin = createAction(
  `[Login] Set login state`,
  props<LoginState>(),
);

export const setUser = createAction(
  `[Login] Set user after Login`,
  props<{ payload: User | null }>(),
);

export const setLoginSpinner = createAction(
  `[Login] Set login spinner state`,
  props<{ loading: boolean }>(),
);

export const fetchUserDetail = createAction(`[Fetch User] Fetch user detail`);

export const logoutUser = createAction(
  `[Logging out completely] Clearing token`,
);

export const redirectAfterLoggingOut = createAction(
  `[Logging out completely] Redirect to the Login page after user was deleted`,
);

export const removeUserLocally = createAction(
  `[User API] Remove User locally after 401 error or logging out`,
);

export const restoreUserLocally = createAction(
  `[User FE action] Restore User from local storage`,
);

export const fetchServicesList = createAction(
  `[User Action] Fetch service list`,
  props<{ location: number | undefined | null; date?: string }>(),
);

export const setServicesList = createAction(
  `[User Action] Set user service list`,
  props<{ services: string[] }>(),
);

export const fetchAvailableSections = createAction(
  `[User Action] Fetch available sections list`,
  props<{ currentService: string; location?: number | null; date?: string }>(),
);

export const setAvailableSections = createAction(
  `[User Action] Set available sections list`,
  props<{ sections: string[] }>(),
);

export const setAvailableSectionsLoading = createAction(
  `[User Action] Set available sections loading`,
  props<{ availableSectionsLoading: boolean }>(),
);

export const saveSectionsSelection = createAction(
  `[Settings] Save sections selection`,
  props<{ sections: string[] | null }>(),
);

export const setSectionsDisplayed = createAction(
  `[Settings] Set sections displayed`,
  props<{ sections: string[] | null }>(),
);

export const changeServiceSettings = createAction(
  `[Settings] Change Service settings`,
  props<{ serviceSettings: Partial<ServiceSettings> }>(),
);

export const saveServiceSettings = createAction(
  `[Settings] Save Service settings locally`,
  props<{ serviceSettings: Partial<ServiceSettings> | null }>(),
);

export const setCurrentService = createAction(
  `[User Action] Set current service`,
  props<{ service: string }>(),
);

export const setCurrentDate = createAction(
  `[User Action] Set current date`,
  props<{ date: string }>(),
);

export const changeCurrentService = createAction(
  `[User Action] Change current service`,
  props<{ service: string }>(),
);

export const setPreviousTableNumber = createAction(
  `[User Action] Set previous table number`,
  props<{ tableNumber: string }>(),
);

export const fetchTables = createAction(`[User Action] Fetch tables`);

export const setTables = createAction(
  `[Settings] Save tables locally`,
  props<{ tables: string[] }>(),
);

export const connectWebSocket = createAction(
  `[Websocket] Connect to websocket`,
);

export const disconnectWebSocket = createAction(
  `[Websocket] Disconnect from websocket`,
);

export const reportWebSocketError = createAction(
  `[Websocket] Connect to websocket error reporting`,
);
