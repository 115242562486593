<resto-reservations-table
  [applyTableFilterReservations]="applyTableFilterReservations$ | async"
  [prefillSavedTable]="prefillSavedTable$ | async"
  [hideCheckedinReservations]="hideCheckedinReservations$ | async"
  [isRequireTableSeating]="isRequireTableSeating$ | async"
  [isRfidDetailsLoading]="isRfidDetailsLoading$ | async"
  [itemsCount]="totalPagesCount$ | async"
  [loadingSpinner]="isTableDataLoading$ | async"
  [ordertakingTableFeature]="hasOrdertakingTableFeature$ | async"
  [prefillLastUsedTable]="prefillLastUsedTable$ | async"
  [previousTableNumber]="previousTableNumber$ | async"
  [reservationConsumers]="reservationConsumers$ | async"
  [rfidDetails]="rfidDetails$ | async"
  [rfidDetailsCount]="rfidDetailsCount$ | async"
  [selectedColumns]="selectedColumns$ | async"
  [showThankYouMessage]="showThankYouMessage"
  [tables]="tables$ | async"
  (applyParams)="retrieveConsumer($event)"
  (changeReservationStatusEvent)="changeReservationStatus($event)"
  (clearReservationsEvent)="clearReservations($event)"
  (clearRfidFieldEvent)="clearRfidField()"
  (deleteReservationsEvent)="deleteReservations($event)"
  (fetchRfidDetailEvent)="fetchRfidDetail($event)"
  (setPreviousTableNumberEvent)="setPreviousTableNumber($event)"
  (patchConsumer)="patchConsumer($event)"
></resto-reservations-table>
