import { createSelector } from '@ngrx/store';
import { OrganisationFeatures, ServiceSettings } from 'src/app/models/user';
import { IState } from '..';

export const selectState = (state: IState) => state.user;

export const selectUser = createSelector(
  selectState,
  (state) => state?.currentUser,
);

export const selectLanguage = createSelector(
  selectState,
  (state) => state?.currentUser?.settings?.language,
);

export const selectIsLogged = createSelector(
  selectState,
  (state) => state?.isLogged,
);

export const selectLoginSpinnerState = createSelector(
  selectState,
  (state) => state?.loading,
);

export const selectServiceList = createSelector(
  selectState,
  (state) => state?.services,
);

export const selectSections = createSelector(
  selectState,
  (state) => state?.sections,
);

export const selectUserLocation = createSelector(
  selectState,
  (state) => state?.currentUser?.location?.id,
);

export const isAvailableSectionsLoading = createSelector(
  selectState,
  (state) => state?.availableSectionsLoading,
);

export const selectSectionsDisplayed = createSelector(
  selectState,
  (state) => state?.sectionsDisplayed,
);

export const selectIsServiceUser = createSelector(
  selectState,
  (state) => state?.currentUser?.is_service,
);

export const selectServiceSettings = createSelector(
  selectState,
  (state) => state?.service_settings,
);

export const selectColumns = createSelector(selectState, (state) => {
  const columnSettings = state.service_settings;
  const selectedColumns: string[] = [];
  if (columnSettings)
    for (const key in columnSettings) {
      if (
        key.includes('show') &&
        columnSettings[key as keyof ServiceSettings]
      ) {
        selectedColumns.push(key.split('_')[2]);
      }
    }
  return selectedColumns;
});

export const selectCurrentService = createSelector(
  selectState,
  (state) => state?.current_service,
);

export const selectCurrentDate = createSelector(
  selectState,
  (state) => state?.current_date,
);

export const selectApplyTableFilterReservations = createSelector(
  selectState,
  (state) => state?.service_settings?.table_filter_reservations,
);

export const selectHideCheckedinReservations = createSelector(
  selectState,
  (state) => state?.service_settings?.hide_checkedin_reservations,
);

export const selectPrefillLastUsedTable = createSelector(
  selectState,
  (state) => state?.service_settings?.table_seating_prefill === 1,
);

export const selectIsDeleteOrder = createSelector(
  selectState,
  (state) => state?.service_settings?.delete_orders,
);

export const selectPrefillSavedTable = createSelector(
  selectState,
  (state) => state?.service_settings?.table_seating_prefill === 2,
);

export const selectIsRequiresTableSeating = createSelector(
  selectState,
  (state) => state?.service_settings?.table_seating,
);

export const selectPreviousTableNumber = createSelector(
  selectState,
  (state) => state?.previous_table_number,
);

export const selectTables = createSelector(
  selectState,
  (state) => state?.tables,
);

export const selectTablesToShow = createSelector(
  selectState,
  (state) => state?.service_settings?.tables,
);

export const selectHasOrdertakingTableFeature = createSelector(
  selectState,
  (state) =>
    state?.currentUser?.organisation?.enabled_features.includes(
      OrganisationFeatures.table_number,
    ) ?? false,
);

export const selectImmedateDone = createSelector(
  selectState,
  (state) => !!state?.service_settings?.immediate_done,
);
