import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public consumers: string;
  public groupedOrders: string;
  public login: string;
  public orders: string;
  public refreshTokenEP: string;
  public search: string;
  public updateConsumerService: string;
  public deleteConsumerService: string;
  public user: string;
  public userDetail: string;
  public updateOrderEP: string;
  public deleteOrderEP: string;
  public searchModel: string;

  constructor() {
    this.consumers = environment.apiUrl + '/consumers/manage/';
    this.groupedOrders = environment.apiUrl + '/orders/tickets_grouped/';
    this.login = environment.apiUrl + '/rest-auth/login/';
    this.orders = environment.apiUrl + '/orders/';
    this.refreshTokenEP = environment.apiUrl + '/rest-auth/token/refresh/';
    this.search = environment.apiUrl + '/orders/search/';
    this.updateConsumerService =
      environment.apiUrl + '/consumer_services/upsert_multiple/';
    this.deleteConsumerService =
      environment.apiUrl + '/consumer_services/delete_multiple/';
    this.user = environment.apiUrl + '/users/me/';
    this.userDetail = environment.apiUrl + '/users/';
    this.updateOrderEP = environment.apiUrl + '/orders/update_multiple/';
    this.deleteOrderEP = environment.apiUrl + '/orders/delete_multiple/';
    this.searchModel = environment.apiUrl + '/search/';
  }
}
