import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConsumerParams, ReservationDetail } from 'src/app/models/reservations';
import {
  selectColumns,
  selectHasOrdertakingTableFeature,
  selectPrefillSavedTable,
  selectIsRequiresTableSeating,
  selectPrefillLastUsedTable,
  selectPreviousTableNumber,
  selectTables,
  selectApplyTableFilterReservations,
  selectHideCheckedinReservations,
} from 'src/app/reducers/user/user.selectors';
import {
  changeReservationStatus,
  deleteReservations,
  fetchConsumers,
  fetchRfidDetails,
  resetReservations,
  resetRfidDetails,
  updateConsumer,
} from 'src/app/reducers/reservations/reservations.actions';
import {
  selectConsumersEntities,
  selectIsRfidDetailsLoading,
  selectIsTableDataLoading,
  selectRfidDetails,
  selectRfidDetailsCount,
  selectTotalReservationsCount,
} from 'src/app/reducers/reservations/reservations.selectors';
import { setPreviousTableNumber } from 'src/app/reducers/user/user.actions';
import { IState } from 'src/app/reducers';
import { AsyncPipe } from '@angular/common';
import { ReservationsTableComponent } from '../reservations-table/reservations-table.component';

@Component({
  selector: 'resto-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss'],
  standalone: true,
  imports: [ReservationsTableComponent, AsyncPipe],
})
export class ReservationsComponent implements OnInit {
  applyTableFilterReservations$ = this.ngrxStore.select(
    selectApplyTableFilterReservations,
  );
  hasOrdertakingTableFeature$ = this.ngrxStore.select(
    selectHasOrdertakingTableFeature,
  );
  prefillSavedTable$ = this.ngrxStore.select(selectPrefillSavedTable);
  isRequireTableSeating$ = this.ngrxStore.select(selectIsRequiresTableSeating);
  isRfidDetailsLoading$ = this.ngrxStore.select(selectIsRfidDetailsLoading);
  isTableDataLoading$ = this.ngrxStore.select(selectIsTableDataLoading);
  prefillLastUsedTable$ = this.ngrxStore.select(selectPrefillLastUsedTable);
  previousTableNumber$ = this.ngrxStore.select(selectPreviousTableNumber);
  reservationConsumers$ = this.ngrxStore.pipe(select(selectConsumersEntities));
  rfidDetails$ = this.ngrxStore.select(selectRfidDetails);
  rfidDetailsCount$ = this.ngrxStore.select(selectRfidDetailsCount);
  selectedColumns$ = this.ngrxStore.select(selectColumns);
  tables$ = this.ngrxStore.select(selectTables);
  totalPagesCount$ = this.ngrxStore.pipe(select(selectTotalReservationsCount));
  hideCheckedinReservations$ = this.ngrxStore.select(
    selectHideCheckedinReservations,
  );

  lastParams!: Partial<ConsumerParams>;
  showThankYouMessage: boolean = false;

  constructor(private ngrxStore: Store<IState>) {}

  ngOnInit(): void {
    this.ngrxStore.dispatch(fetchConsumers({}));
  }

  retrieveConsumer(params: Partial<ConsumerParams>): void {
    this.lastParams = params;
    this.ngrxStore.dispatch(fetchConsumers({ params }));
  }

  setPreviousTableNumber(data: { tableNumber: string }): void {
    this.ngrxStore.dispatch(
      setPreviousTableNumber({ tableNumber: data.tableNumber }),
    );
  }

  changeReservationStatus(details: {
    data: Partial<ReservationDetail>;
    callback?: boolean;
    change: boolean;
  }): void {
    const callback = () => {
      this.showThankYouMessage = true;
      setTimeout(() => {
        this.clearRfidField();
      }, 3000);
    };
    this.ngrxStore.dispatch(
      changeReservationStatus({
        data: details.data,
        change: details.change,
        ...(details.callback ? { callback } : {}),
      }),
    );
  }

  clearRfidField(): void {
    this.ngrxStore.dispatch(resetRfidDetails());
    this.showThankYouMessage = false;
  }

  deleteReservations(details: { data: Partial<ReservationDetail> }): void {
    this.ngrxStore.dispatch(deleteReservations({ data: details.data }));
  }

  clearReservations(details: { data: Partial<ReservationDetail> }): void {
    this.ngrxStore.dispatch(resetReservations({ data: details.data }));
  }

  fetchRfidDetail(data: { rfid: string }): void {
    this.ngrxStore.dispatch(fetchRfidDetails({ rfid: data.rfid }));
  }

  patchConsumer({
    data,
    url,
  }: {
    data: Partial<ReservationDetail>;
    url: string;
  }): void {
    this.ngrxStore.dispatch(
      updateConsumer({
        url,
        data,
      }),
    );
  }
}
