import {
  Directive,
  Self,
  AfterViewInit,
  Input,
  DestroyRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { FieldErrors } from 'src/app/models/authentication';
import { IState } from 'src/app/reducers';
import { clearFormAndFieldErrors } from 'src/app/reducers/shared/shared.actions';
import { selectFormErrors } from 'src/app/reducers/shared/shared.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector:
    '[formControl][restoServerFieldError], [formControlName][restoServerFieldError]',
  standalone: true,
})
export class ServerFieldErrorDirective implements AfterViewInit {
  private serverErrorShown = false;

  @Input() controlName!: string;

  constructor(
    @Self() private controlDir: NgControl,
    private store: Store<IState>,
    private destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit(): void {
    this.controlDir?.control?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.handleServerError();
      });
    const fieldName = (this.controlDir.name as string) || this.controlName;
    this.store
      .pipe(
        select(selectFormErrors),
        filter((error) => !!(error && (error as FieldErrors)[fieldName])),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((error) => {
        if (!error) return;
        this.serverErrorShown = true;
        this.controlDir?.control &&
          this.controlDir.control.setErrors(
            { error: (error as FieldErrors)[fieldName] },
            { emitEvent: true },
          );
      });
  }

  handleServerError = () => {
    if (this.serverErrorShown) {
      this.serverErrorShown = false;
      this.store.dispatch(
        clearFormAndFieldErrors({
          payload: (this.controlName ||
            (this.controlDir?.name &&
              this.controlDir.name.toString())) as string,
        }),
      );
    }
  };
}
