<button
  mat-fab
  extended
  color="primary"
  class="order-status"
  [disabled]="disableButton"
  [ngClass]="order.vip ? 'vip-order-status' : 'not-vip-order-status'"
  (click)="startTimer()"
>
  <mat-icon
    class="send-icon"
    [ngClass]="order.vip ? 'vip-icon' : 'not-vip-icon'"
    >bolt</mat-icon
  >
  @if (subscribeTimer > 0 && disableButton) {
    {{ subscribeTimer }}
  }
</button>
