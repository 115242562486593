@for (itemName of item; track itemName; let idx = $index) {
  <span
    class="order-item"
    [class.highlight-item]="
      (!samePortionSize && portionSize[idx] !== 1) ||
      (!sameTexture && texture[idx]) ||
      (!sameConsistency && consistency[idx])
    "
  >
    @if (quantity[idx] > 1) {
      <span class="quantity">{{ quantity[idx] }} &times; </span>
    }
    <span class="name">
      {{ itemName }}
      @if (variants[idx]) {
        <span class="variant">: {{ variants[idx] }}</span>
      }
    </span>
    @if (description[idx]) {
      <span class="description"> ({{ description[idx] }})</span>
    }
    @if (!samePortionSize && portionSize[idx] !== 1) {
      <span class="dief-info portion">
        {{
          'orders.table.data.portion-size.' + portionSize[idx] * 100 | transloco
        }}</span
      >
    }
    @if (!sameTexture && texture[idx]) {
      <span class="diet-info">
        {{ 'orders.table.data.texture.' + texture[idx] | transloco }}</span
      >
    }
    @if (!sameConsistency && consistency[idx]) {
      <span class="diet-info">
        {{
          'orders.table.data.consistency.' + consistency[idx] | transloco
        }}</span
      >
    }
  </span>
}
@if (samePortionSize && portionSize[0] !== 1) {
  <span class="diet-info portion">
    {{
      'orders.table.data.portion-size.' + portionSize[0] * 100 | transloco
    }}</span
  >
}
@if (diets.length && diets[0]) {
  <span class="diet-info"> {{ diets[0] }}</span>
}
@if (sameTexture && texture[0]) {
  <span class="diet-info">
    {{ 'orders.table.data.texture.' + texture[0] | transloco }}</span
  >
}
@if (sameConsistency && consistency[0]) {
  <span class="diet-info">
    {{ 'orders.table.data.consistency.' + consistency[0] | transloco }}</span
  >
}
<span class="edit-icon icon-button-density-3">
  <button mat-icon-button (click)="openCommentDialog()">
    <mat-icon>chat_paste_go</mat-icon>
  </button>
</span>
