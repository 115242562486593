import { LANGUAGES } from '../shared/constants/languages';

export interface LoginSuccess {
  access: string;
  refresh: string;
  user: User;
}

export interface User {
  id: number;
  url: string;
  organisation: Organisation;
  location: Location;
  terminal: Terminal | null;
  status: UserStatus;
  settings: UserSettings;
  username: string;
  first_name: string;
  last_name: string;
  is_user: true;
  is_kds: boolean;
  is_service: boolean;
  is_staff: boolean;
  email: string;
  role: 1 | 2 | 3 | 4 | 5;
  type_name: string;
  custom_data: CustomData;
  diets_detail: DietDetailShort[];
  name: string;
  diets: string[];
  type: number;
  checkin: string;
  checkout: string;
  service: ServiceSettings;
  external_id: string;
  vip: boolean;
}
export interface ServiceSettings {
  categories: number;
  colour_mode: number;
  delete_orders: boolean;
  immediate_done: boolean;
  show_consumer_diets: boolean;
  show_consumer_name: boolean;
  show_consumer_profile: boolean;
  show_consumer_room: boolean;
  show_consumer_type: boolean;
  table_filter_reservations: boolean;
  hide_checkedin_reservations: boolean;
  table_seating: boolean;
  table_seating_prefill: 0 | 1 | 2;
  tables: string[];
}

export interface CustomData {
  [key: string]: string | number | boolean;
}

export interface DietDetailShort {
  diet_name: string;
  diet: number;
  info_url: string;
}
export interface UserSettings {
  language: keyof typeof LANGUAGES;
  timezone: string;
  orderby_time: string;
  orderby_days_offset: number;
  columns_consumers: string[];
  columns_users: string[];
  columns_orders: string[];
  sorting_consumers: string;
  sorting_orders: string;
  sorting_users: string;
}

export interface Terminal {
  id: number;
  name: string;
  identifier: string;
  order_until: string;
  order_days_before: number;
  type: 1 | 2 | 3;
  privacy: 1 | 2 | 3;
}

export interface UserStatus {
  consumer: string;
  orderby_time: string;
  orderby_days_offset: number;
  orderby_until: number;
  email_verified: boolean;
  date_joined: string;
  user_identifier: number;
}

export interface Location {
  id: number;
  logo: string;
  name: string;
  order_until: string;
  order_days_before: number;
  allow_order_allergies?: boolean;
  allow_order_intolerances?: boolean;
}

export interface Organisation {
  id: number;
  identifier: string;
  logo: string;
  name: string;
  acronym: string;
  order_until: string;
  order_days_before: number;
  order_days_until: number;
  supported_custom_data: SupportedCustomData[];
  supported_custom_data_consumers: SupportedCustomData[];
  enabled_features: OrganisationFeatures[];
  enabled_reports: string[];
  date_joined: string;
  allow_order_allergies?: boolean;
  allow_order_intolerances?: boolean;
}
export interface Consumer {
  allergies: string[];
  birthday: string;
  checkin: string;
  checkout: string;
  consistency: number;
  created_at: string;
  created_by: number;
  custom_data: CustomData;
  diets_detail: DietDetailShort[];
  diets: string[];
  has_birthday: boolean;
  id: number;
  is_user: boolean;
  intolerances: string[];
  last_modified_at: string;
  last_modified_by: number;
  location_name: string;
  location: number;
  name: string;
  organisation: Organisation;
  portion_size: number;
  rfid: string;
  room_floor: string;
  room: string;
  service_status: boolean;
  service_status_table: string;
  table_number: string;
  texture: number;
  type_name: string;
  type: number;
  typology: string;
  url: string;
  uuid: string;
  vip: boolean;
}

export interface SupportedCustomData {
  key: string;
  type: SupportedCustomDataTypes;
  order: number;
  locations?: number[];
}

export type SupportedCustomDataTypes = 'string' | 'number' | 'boolean' | 'text';

export enum OrganisationFeatures {
  birthdays = 'birthdays',
  block_editor_invite = 'block_editor_invite',
  checkin = 'checkin',
  checkout = 'checkout',
  consistency = 'consistency',
  texture = 'texture',
  aggregated_ordertaking = 'aggregated_ordertaking',
  external_id = 'external_id',
  ordering_multiple = 'ordering_multiple',
  rfid = 'rfid',
  room = 'room',
  room_floor = 'room_floor',
  staff_ordertaking = 'staff_ordertaking',
  table_number = 'table_number',
  qr_code = 'qr_code',
  public_link = 'public_link',
  automated_order_consumers = 'automated_order_consumers',
  automated_order_users = 'automated_order_users',
  portion_size = 'portion_size',
}
