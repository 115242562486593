import { ActionCreator, Action } from '@ngrx/store';

export interface Results<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
  numberOfPages: number;
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export function reverseSorting(sortingOption: string): string {
  return sortingOption.startsWith(`-`)
    ? sortingOption.substring(1, sortingOption.length)
    : `-${sortingOption}`;
}

export type GenericAction = ActionCreator<
  string,
  (props: { [key: string]: any }) => { [key: string]: any } & Action<string>
>;

export type Primitive = string | number | boolean;
