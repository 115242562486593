import { createAction, props } from '@ngrx/store';
import {
  AddOrderParams,
  GroupedTickets,
  Order,
  OrdersParams,
} from 'src/app/models/orders';
import { ReservationDetail } from 'src/app/models/reservations';
import { ServiceStatus } from './../../models/orders';
import { WebsocketAction } from 'src/app/models/websocket';

export const fetchOrders = createAction(
  `[Orders] Fetch orders`,
  props<{
    params: { service_status: ServiceStatus } & Partial<OrdersParams>;
  }>(),
);

export const setOrdersLoading = createAction(
  `[Orders] Set orders loading`,
  props<{ loading: boolean; service_status: ServiceStatus }>(),
);

export const clearOrders = createAction(`[Orders] Clear orders locally`);

export const setOrders = createAction(
  `[Orders] Set orders locally`,
  props<{ items: GroupedTickets[]; service_status: ServiceStatus }>(),
);

export const updateOrderComment = createAction(
  `[Orders] Update order comment (description)`,
  props<{
    id: number;
    comment: string;
  }>(),
);

export const updateOrderStatus = createAction(
  `[Orders] Update order status`,
  props<{
    ids: number[];
    previous_status: ServiceStatus;
    new_status: ServiceStatus;
  }>(),
);

export const changeOrderStatus = createAction(
  `[Orders] Set updated orders status locally`,
  props<{
    ids: number[];
    previous_status: ServiceStatus;
    new_status: ServiceStatus;
    items: Order[];
  }>(),
);

export const changeOrderTable = createAction(
  `[Orders] Change table number`,
  props<{ data: Partial<ReservationDetail>; service_status: ServiceStatus }>(),
);

export const deleteOrders = createAction(
  `[Orders] Delete orders`,
  props<{
    ids: number[];
    service_status: ServiceStatus;
    count: number;
    section: string;
  }>(),
);

export const removeOrders = createAction(
  `[Orders] Remove orders locally`,
  props<{ ids: number[]; service_status: ServiceStatus }>(),
);

export const addSpecialOrder = createAction(
  `[Orders] Add orders`,
  props<{
    data: { service_status: ServiceStatus } & Partial<AddOrderParams>;
  }>(),
);

export const setNewSpecialOrder = createAction(
  `[Orders] Add special orders locally`,
  props<{ order: Order; service_status: ServiceStatus }>(),
);

export const setNewOrders = createAction(
  `[Orders] Add orders locally`,
  props<{ items: Order[]; service_status: ServiceStatus }>(),
);

export const processWebsocketsData = createAction(
  `[Tickets] Processes Websockets data`,
  props<{
    message: {
      action: WebsocketAction;
      date: string;
      service: string;
      data: any;
    };
  }>(),
);

export const expediteOrder = createAction(
  `[Fired Orders] Expedite order`,
  props<{ ids: number[]; selectedReason: string; value: boolean }>(),
);

export const changeOrders = createAction(
  `[Orders] Change fired orders`,
  props<{ items: Order[]; service_status: ServiceStatus }>(),
);

export const clearAllOrders = createAction(`[Orders] Clear all orders`);
