import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addSpecialOrder,
  changeOrderTable,
  deleteOrders,
  fetchOrders,
  updateOrderComment,
  updateOrderStatus,
} from 'src/app/reducers/orders/orders.actions';
import {
  AddOrderParams,
  OrdersParams,
  ServiceStatus,
  UpdateOrderParams,
} from 'src/app/models/orders';
import { ReservationDetail } from 'src/app/models/reservations';
import {
  selectIsTableDataLoadingOrders,
  selectOrdersItems,
  selectTotalOrdersCount,
} from 'src/app/reducers/orders/orders.selectors';
import {
  selectColumns,
  selectCurrentService,
  selectIsRequiresTableSeating,
  selectSections,
  selectTablesToShow,
} from 'src/app/reducers/user/user.selectors';
import { IState } from 'src/app/reducers';
import { take } from 'rxjs';
import { resetReservations } from 'src/app/reducers/reservations/reservations.actions';
import { setSectionFilter } from 'src/app/reducers/shared/shared.actions';
import { selectSectionFilter } from 'src/app/reducers/shared/shared.selectors';
import { AsyncPipe } from '@angular/common';
import { OrdersTableComponent } from '../orders-table/orders-table.component';

@Component({
  selector: 'resto-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  standalone: true,
  imports: [OrdersTableComponent, AsyncPipe],
})
export class OrdersComponent implements OnInit {
  availableSections$ = this.ngrxStore.select(selectSections);
  isRequireTableSeating$ = this.ngrxStore.select(selectIsRequiresTableSeating);
  isTableDataLoading$ = this.ngrxStore.select(selectIsTableDataLoadingOrders);
  lastParams!: Partial<OrdersParams>;
  orders$ = this.ngrxStore.select(selectOrdersItems);
  selectedColumns$ = this.ngrxStore.select(selectColumns);
  sectionFilter$ = this.ngrxStore.select(selectSectionFilter);
  tablesToShow$ = this.ngrxStore.select(selectTablesToShow);
  totalOrdersCount$ = this.ngrxStore.select(selectTotalOrdersCount);

  constructor(private ngrxStore: Store<IState>) {}

  ngOnInit(): void {
    this.ngrxStore
      .select(selectCurrentService)
      .pipe(take(1))
      .subscribe((val) => {
        if (val !== undefined)
          this.ngrxStore.dispatch(
            fetchOrders({ params: { service_status: 2 } }),
          );
      });
  }

  doneOrder(data: { ids: number[] }): void {
    this.ngrxStore.dispatch(
      updateOrderStatus({
        ids: data.ids,
        previous_status: 2,
        new_status: 4,
      }),
    );
  }

  retrieveOrders(params: Partial<OrdersParams>) {
    this.lastParams = params;
    this.ngrxStore.dispatch(
      fetchOrders({
        params: {
          ...params,
          service_status: 2,
        },
      }),
    );
  }

  fireOrder(data: { ids: number[] }): void {
    this.ngrxStore.dispatch(
      updateOrderStatus({ ids: data.ids, previous_status: 2, new_status: 3 }),
    );
  }

  changeOrderTable(details: { data: Partial<ReservationDetail> }): void {
    this.ngrxStore.dispatch(
      changeOrderTable({
        data: details.data,
        service_status: 2,
      }),
    );
  }

  deleteOrder({
    ids,
    count,
    section,
  }: {
    ids: number[];
    count: number;
    section: string;
  }): void {
    this.ngrxStore.dispatch(
      deleteOrders({ ids, service_status: 2, count, section }),
    );
  }

  undoOrder(detail: { data: Partial<UpdateOrderParams> }): void {
    this.ngrxStore.dispatch(resetReservations({ data: detail.data }));
  }

  addOrder(detail: {
    data: { service_status: ServiceStatus } & Partial<AddOrderParams>;
  }): void {
    this.ngrxStore.dispatch(addSpecialOrder({ data: detail.data }));
  }

  filterBySection(section: string | null): void {
    this.ngrxStore.dispatch(setSectionFilter({ section }));
    [2, 3, 4].forEach((status) => {
      this.ngrxStore.dispatch(
        fetchOrders({
          params: {
            service_status: status,
          },
        }),
      );
    });
  }

  updateComment(data: { id: number; comment: string }): void {
    this.ngrxStore.dispatch(updateOrderComment(data));
  }
}
