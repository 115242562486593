@if (globalError$ | async) {
  <resto-error-banner
    class="notification"
    [error]="globalError$ | async"
  ></resto-error-banner>
}
@if ((isLoggedIn$ | async) && (isServiceUser$ | async)) {
  <mat-drawer-container class="drawer-container" [hasBackdrop]="true">
    <mat-drawer #drawer position="end" [mode]="'side'">
      <resto-settings
        [currentService]="currentService$ | async"
        [hasOrdertakingTableFeature]="hasOrdertakingTableFeature$ | async"
        [serviceSettings]="serviceSettings$ | async"
        [user]="user$ | async"
        [tables]="tables$ | async"
        (toggle)="drawer.toggle()"
      ></resto-settings>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <main class="wrapper">
        <resto-navigation-filters
          [language]="(user$ | async)?.settings?.language!"
          [serviceList]="availableServices$ | async"
          [userLocation]="userLocation$ | async"
        ></resto-navigation-filters>
        <button
          mat-button
          class="menu-toggle"
          [ngClass]="!drawer.opened ? 'toggle-button' : 'hide'"
          (click)="drawer.toggle()"
        >
          <mat-icon class="menu-icon">menu_open</mat-icon>
        </button>
        <resto-header
          [immediateDone]="immediateDone$ | async"
          [doneOrdersCount]="doneOrdersCount$ | async"
          [firedOrdersCount]="firedOrdersCount$ | async"
          [ordersCount]="ordersCount$ | async"
          [reservationsCount]="reservationsCount$ | async"
        ></resto-header>
        <router-outlet></router-outlet>
      </main>
      <resto-footer
        [showLogout]="(isLoggedIn$ | async) ?? false"
      ></resto-footer>
    </mat-drawer-content>
  </mat-drawer-container>
} @else {
  <router-outlet></router-outlet>
  <resto-footer [showLogout]="(isLoggedIn$ | async) ?? false"></resto-footer>
}
