<resto-fired-orders-table
  [clock]="clock$ | async"
  [firedOrders]="firedOrders$ | async"
  [isRequireTableSeating]="isRequireTableSeating$ | async"
  [itemsCount]="totalOrdersCount$ | async"
  [loadingSpinner]="isTableDataLoading$ | async"
  [selectedColumns]="selectedColumns$ | async"
  [sectionFilter]="sectionFilter$ | async"
  [sections]="availableSections$ | async"
  [tablesToShow]="tablesToShow$ | async"
  (addOrderEvent)="addOrder($event)"
  (applyParams)="retrieveOrders($event)"
  (changeOrderTableEvent)="changeOrderTable($event)"
  (deleteOrder)="deleteOrder($event)"
  (doneOrder)="doneOrder($event)"
  (expediteOrder)="expediteOrder($event)"
  (filterBySection)="filterBySection($event)"
  (undoOrder)="undoOrder($event)"
  (updateComment)="updateComment($event)"
></resto-fired-orders-table>
