import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  Output,
} from '@angular/core';
import { TableBaseComponent } from './table-component.base';
import { SelectionModel } from '@angular/cdk/collections';
import { Order } from 'src/app/models/orders';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'resto-table-container',
  template: ``,
})
export class OrderTableBaseComponent
  extends TableBaseComponent
  implements AfterViewInit, OnChanges
{
  @Output() deleteOrder = new EventEmitter<{
    ids: number[];
    count: number;
    section: string;
  }>();
  @Output() filterBySection = new EventEmitter<string | null>();

  selection = new SelectionModel<Order>(true, []);

  deleteSelectedOrders(): void {
    // group this.selection.selected by table_number with a list of ids as value
    const groupedIds = this.selection.selected.reduce(
      (acc, order) => {
        const section = order.section;
        if (acc[section]) {
          acc[section].ids.push(...order.ids);
          acc[section].count++;
        } else {
          acc[section] = {
            count: 1,
            ids: [...order.ids],
          };
        }
        return acc;
      },
      {} as { [key: string]: { count: number; ids: number[] } },
    );
    // emit one event per section
    Object.keys(groupedIds).forEach((section) => {
      this.deleteOrder.emit({
        ids: groupedIds[section].ids,
        count: groupedIds[section].count,
        section,
      });
    });
    // clear selection
    this.selection.clear();
  }

  isGroup(
    index: number,
    item: { table_number: string; isGroupBy: boolean },
  ): boolean {
    return item.isGroupBy;
  }

  selectOrder(
    event: MatOptionSelectionChange<string> | MatOptionSelectionChange<null>,
  ): void {
    if (event && event.isUserInput) {
      this.filterBySection.emit(event.source.value);
    }
  }
}
