import { createSelector } from '@ngrx/store';
import { IState } from '..';

export const selectState = (state: IState) => state.shared;

export const selectFormErrors = createSelector(
  selectState,
  (state) => state?.form_errors,
);

export const selectGlobalError = createSelector(
  selectState,
  (state) => state?.global_error,
);

export const selectSectionFilter = createSelector(
  selectState,
  (state) => state?.section_filter,
);
