<div class="table mat-elevation-z4">
  <div class="filters">
    <div class="select-order">
      <span
        [matTooltip]="
          !selection.selected.length ? ('fired.table.tool-tip' | transloco) : ''
        "
      >
        <button
          [matMenuTriggerFor]="addOrder"
          [disabled]="!selection.selected.length"
          color="primary"
          class="add-order-btn"
          mat-flat-button
        >
          {{ 'fired.table.actions.add-order' | transloco }}
        </button>
      </span>
      <mat-menu #addOrder>
        <div class="add-order" (click)="$event.stopPropagation()">
          <mat-form-field class="add-order-field" subscriptSizing="dynamic">
            <textarea
              (click)="$event.stopPropagation()"
              matInput
              name="order"
              [placeholder]="'fired.table.order' | transloco"
              [formControl]="orderDetail"
            ></textarea>
          </mat-form-field>
          @if (sections?.length) {
            <mat-form-field
              class="add-order-field"
              appearance="fill"
              subscriptSizing="dynamic"
            >
              <mat-select
                (click)="$event.stopPropagation()"
                [placeholder]="'fired.table.section' | transloco"
                [formControl]="selectSectionFormControl"
              >
                <mat-option
                  (onSelectionChange)="selectSection($event)"
                  [value]="null"
                >
                  {{ 'fired.table.filters.all' | transloco }}
                </mat-option>
                @for (option of sections; track option) {
                  <mat-option
                    (onSelectionChange)="selectSection($event)"
                    [value]="option"
                    >{{ option }}</mat-option
                  >
                }
              </mat-select>
            </mat-form-field>
          }
          <button
            (click)="addSpecialOrder()"
            class="add-order-button"
            [disabled]="
              !orderDetail.value || selectSectionFormControl.value === null
            "
            mat-flat-button
            color="primary"
          >
            {{
              'fired.table.add-special-orders'
                | transloco: { noOfOrders: selection.selected.length }
            }}
          </button>
        </div>
      </mat-menu>
      @if (sections?.length) {
        <ng-container class="select-order">
          <span class="filter-sections-label">{{
            'fired.table.filters.show-orders' | transloco
          }}</span>
          <mat-form-field
            class="services field-density-3"
            appearance="fill"
            subscriptSizing="dynamic"
          >
            <mat-select
              [formControl]="selectOrderFormControl"
              [placeholder]="'orders.table.filters.all' | transloco"
            >
              <mat-option
                (onSelectionChange)="selectOrder($event)"
                [value]="null"
              >
                {{ 'fired.table.filters.all' | transloco }}
              </mat-option>
              @for (option of sections; track option) {
                <mat-option
                  (onSelectionChange)="selectOrder($event)"
                  [value]="option"
                  >{{ option }}</mat-option
                >
              }
            </mat-select>
          </mat-form-field>
        </ng-container>
      }
    </div>
    @if (!!selection.selected.length) {
      <div class="action-button">
        <span class="selected-orders">
          {{
            'fired.table.actions.selected'
              | transloco: { count: selection.selected.length }
          }}
        </span>
        <div
          class="btn-wrapper"
          [matTooltip]="
            !selection.selected.length
              ? ('fired.table.tool-tip' | transloco)
              : ''
          "
        >
          <button
            [matMenuTriggerFor]="
              isRequireTableSeating ? multipleConsumerTableInput : null
            "
            [disabled]="!selection.selected.length"
            class="change-table"
            mat-flat-button
            color="primary"
            (click)="
              !isRequireTableSeating ? multipleConsumerTableUpdate() : null
            "
          >
            <ng-container>
              {{ 'fired.table.actions.change-table' | transloco }}
              <mat-icon class="table-icon">table_bar</mat-icon>
            </ng-container>
          </button>
          <mat-menu #multipleConsumerTableInput>
            <mat-form-field class="table-input" subscriptSizing="dynamic">
              <input
                (click)="$event.stopPropagation()"
                matInput
                type="text"
                name="table_number_multiple"
                [placeholder]="'fired.table.table-number' | transloco"
                [formControl]="tableNumber"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="multipleConsumerTableUpdate()"
              >
                <mat-icon>check</mat-icon>
              </button>
            </mat-form-field>
          </mat-menu>
        </div>
        <div
          class="btn-wrapper"
          [matTooltip]="
            !selection.selected.length
              ? ('fired.table.tool-tip' | transloco)
              : ''
          "
        >
          <button
            mat-flat-button
            class="expedite-btn expedite-all"
            [disabled]="!selection.selected.length"
            [matMenuTriggerFor]="expediteReasons"
          >
            <ng-container>
              <mat-icon class="expedite-all-icon">bolt</mat-icon>
              <mat-icon class="expedite-all-icon">
                notification_important
              </mat-icon>
            </ng-container>
          </button>
          <mat-menu #expediteReasons>
            <span mat-menu-item (click)="expediteAllOrders(1)">{{
              'fired.table.actions.expedite-reasons.early-leave' | transloco
            }}</span>
            <span mat-menu-item (click)="expediteAllOrders(2)">{{
              'fired.table.actions.expedite-reasons.medical-reason' | transloco
            }}</span>
            <span mat-menu-item (click)="expediteAllOrders(3)">{{
              'fired.table.actions.expedite-reasons.other' | transloco
            }}</span>
          </mat-menu>
        </div>
        <div
          class="btn-wrapper"
          [matTooltip]="
            !selection.selected.length
              ? ('fired.table.tool-tip' | transloco)
              : ''
          "
        >
          <button
            [disabled]="!selection.selected.length"
            class="done-all"
            mat-flat-button
            color="primary"
            (click)="doneAllOrders()"
          >
            <ng-container>
              <mat-icon class="table-icon">check</mat-icon>
            </ng-container>
          </button>
        </div>
        <div
          class="btn-wrapper"
          [matTooltip]="
            !selection.selected.length
              ? ('fired.table.tool-tip' | transloco)
              : ''
          "
        >
          <button
            mat-icon-button
            [disabled]="!selection.selected.length"
            class="undo-fire-orders"
            (click)="undoSelectedOrders()"
          >
            <mat-icon>undo</mat-icon>
          </button>
        </div>
        <div
          class="btn-wrapper"
          [matTooltip]="
            !selection.selected.length
              ? ('fired.table.tool-tip' | transloco)
              : ''
          "
        >
          <button
            mat-icon-button
            [disabled]="!selection.selected.length"
            [matMenuTriggerFor]="deleteMenu"
            class="delete-fire-orders"
          >
            @if (selection.selected.length <= 1) {
              <mat-icon>delete</mat-icon>
            }
            @if (selection.selected.length > 1) {
              <mat-icon>delete_sweep</mat-icon>
            }
          </button>
          <mat-menu #deleteMenu>
            <span class="delete-menu-label" mat-menu-item>
              {{ 'fired.table.actions.are-you-sure' | transloco }}</span
            >
            <button
              mat-menu-item
              class="delete-menu-button"
              (click)="deleteSelectedOrders()"
            >
              @if (selection.selected.length <= 1) {
                <mat-icon>delete</mat-icon>
              }
              @if (selection.selected.length > 1) {
                <mat-icon>delete_sweep</mat-icon>
              }
              {{
                selection.selected.length <= 1
                  ? ('fired.table.actions.delete' | transloco)
                  : ('fired.table.actions.delete-all' | transloco)
              }}
            </button>
          </mat-menu>
        </div>
      </div>
    }
  </div>
  <mat-divider></mat-divider>
  @if (!loadingSpinner && itemsCount === 0) {
    <div class="empty-table">
      <div class="mat-h2 no-items">
        {{ 'shared.tables.zzz' | transloco }}
      </div>
      <div>{{ 'shared.tables.empty' | transloco }}</div>
    </div>
  } @else {
    @if (dataSourceToDisplay !== null && !loadingSpinner) {
      <table
        mat-table
        #table
        class="data-table mobile-support"
        [dataSource]="dataSourceToDisplay"
      >
        <ng-container matColumnDef="select">
          <mat-header-cell class="select-column header" *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell class="select-column" *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell class="consumer-column" *matHeaderCellDef
            ><div [innerHTML]="'fired.table.headers.name' | transloco"></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="consumer-column">
            <span class="consumer-name">
              <span>
                {{ element.name }}
                @if (element.room) {
                  ({{ element.room }})
                }
              </span>
              @if (element.vip) {
                <mat-icon class="vip">local_activity</mat-icon>
              }
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="time">
          <mat-header-cell class="header waiting-time-column" *matHeaderCellDef
            ><div
              [innerHTML]="'fired.table.headers.waiting-time' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="waiting-time waiting-time-column"
            [class.waiting-time-vip]="element.vip"
            [class.waiting-time-expedite]="element.service_expedite"
          >
            <resto-fired-waiting-time
              [time]="element.service_fired_time"
              [clock]="clock"
            ></resto-fired-waiting-time>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="order">
          <mat-header-cell class="header order-item-column" *matHeaderCellDef
            ><div [innerHTML]="'fired.table.headers.order' | transloco"></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="order-item-column">
            @if (element.is_special_order) {
              <div class="special-order">
                {{ 'fired.table.special-order' | transloco }}
              </div>
            }
            <resto-order-item
              [quantity]="element.quantity"
              [texture]="element.texture"
              [consistency]="element.consistency"
              [portionSize]="element.portion_size"
              [item]="element.item"
              [itemOption]="element.item_option"
              [description]="element.description"
              [variants]="element.variants"
              [diets]="element.order_diets"
              [description]="element.description"
              (updateComment)="
                updateComment.emit({
                  id: element.ids.slice(-1)[0],
                  comment: $event,
                })
              "
            ></resto-order-item>
            <span class="order-actions">
              <button
                mat-fab
                extended
                class="expedite-btn warn-button"
                [matMenuTriggerFor]="
                  !element.service_expedite ? expediteReasons : null
                "
                [ngClass]="
                  element.service_expedite ? 'expedite' : 'non-expedite'
                "
                (click)="
                  element.service_expedite
                    ? expedite(element.id, element.ids, 0, true)
                    : null
                "
                color="primary"
              >
                <mat-icon>bolt</mat-icon>
                <mat-icon class="notification">
                  notification_important
                </mat-icon>
                <resto-fired-count-down
                  [order]="element"
                  [selectedReason]="selectedReason"
                  [showCountDown]="ordersBeingExpedite.includes(element.id)"
                  [startCountDown]="startCountDown"
                  (countDownComplete)="
                    countDownComplete($event);
                    checkForMultipleExpediteOrder($event)
                  "
                  (expediteOrder)="expediteOrderEvent($event)"
                ></resto-fired-count-down>
              </button>
              <button
                mat-fab
                extended
                class="done-order"
                color="primary"
                (click)="doneAOrder(element.ids)"
              >
                <ng-container>
                  <mat-icon class="table-icon">check</mat-icon>
                </ng-container>
              </button>
            </span>
            <mat-menu #expediteReasons>
              <span
                mat-menu-item
                (click)="expedite(element.id, element.ids, 1)"
                >{{
                  'fired.table.actions.expedite-reasons.early-leave' | transloco
                }}</span
              >
              <span
                mat-menu-item
                (click)="expedite(element.id, element.ids, 2)"
                >{{
                  'fired.table.actions.expedite-reasons.medical-reason'
                    | transloco
                }}</span
              >
              <span
                mat-menu-item
                (click)="expedite(element.id, element.ids, 3)"
                >{{
                  'fired.table.actions.expedite-reasons.other' | transloco
                }}</span
              >
            </mat-menu>
            @if (element.service_expedite_time) {
              <div class="expedite-time waiting-time-expedite">
                <resto-fired-waiting-time
                  [time]="element.service_expedite_time"
                  [clock]="clock"
                ></resto-fired-waiting-time>
              </div>
            }
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="filteredColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: filteredColumns"
          [class.row-selected]="selectedRowId === row.id"
          (click)="selectRow(row)"
        ></mat-row>
        <ng-container matColumnDef="groupHeader">
          <mat-cell mat-cell *matCellDef="let groupBy">
            <mat-checkbox
              class="group-header-checkbox"
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="
                $event ? selectTableOrders(groupBy.table_number, $event) : null
              "
              [checked]="checkTableHeader(groupBy.table_number)"
            >
            </mat-checkbox>
            <span class="header-text"
              >{{ 'fired.table.headers.table' | transloco }}&nbsp;
              <strong>{{
                groupBy.table_number
                  ? groupBy.table_number
                  : ('fired.table.headers.unassigned' | transloco)
              }}</strong></span
            >
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell
            class="header template-cell column-type"
            *matHeaderCellDef
            ><div
              [innerHTML]="'reservations.table.headers.type' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="template-cell column-type">
            {{ element.type_name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="diets">
          <mat-header-cell
            class="header template-cell column-diets"
            *matHeaderCellDef
            ><div
              [innerHTML]="'reservations.table.headers.diet' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="template-cell column-diets"
          >
            <span class="diet-names">
              @if (element.diets?.length > 0) {
                @for (diet of element.diets; track diet) {
                  <span>{{ diet }}</span>
                }
              } @else {
                <span>-</span>
              }
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="profile">
          <mat-header-cell
            class="header template-cell comment-cell"
            *matHeaderCellDef
            ><div
              [innerHTML]="'reservations.table.headers.comments' | transloco"
            ></div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="template-cell comment-cell"
          >
            @if (
              element?.custom_data &&
              (element.custom_data | keyvalue).length > 0
            ) {
              <span>
                @for (item of element?.custom_data | keyvalue; track item) {
                  <resto-consumer-data-item
                    class="item"
                    [label]="item.key"
                    [value]="item.value"
                  ></resto-consumer-data-item>
                }
              </span>
            } @else {
              <span>-</span>
            }
          </mat-cell>
        </ng-container>
        <mat-row
          mat-row
          *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
          class="group-header"
        ></mat-row>
      </table>
    }
  }
  @if (loadingSpinner) {
    <div class="spinner-container">
      <mat-spinner class="loading-spinner" mode="indeterminate"></mat-spinner>
    </div>
  }
</div>
