@if (icon) {
  <mat-icon>{{ icon }}</mat-icon>
}
@if (type !== 'checkbox') {
  <mat-form-field
    class="form-field"
    [class.fix-form-height]="type === 'select' && (isFirefox || isSafari)"
  >
    <mat-label>{{ label || placeholder }}</mat-label>
    @switch (type) {
      @case ('date') {
        <input
          (click)="picker.open()"
          matInput
          #datepickerInput
          [name]="formControlName"
          [matDatepicker]="picker"
          [formControl]="control"
          (dateChange)="changeDate($event)"
        />
        <mat-datepicker #picker></mat-datepicker>
      }
      @case ('textarea') {
        <textarea
          matInput
          cols="30"
          rows="2"
          restoServerFieldError
          style="resize: none"
          [name]="formControlName"
          [controlName]="formControlName"
          [formControl]="control"
          [required]="required"
        ></textarea>
      }
      @default {
        <input
          #inputElement
          matInput
          restoServerFieldError
          [name]="formControlName"
          [controlName]="formControlName"
          [formControl]="control"
          [required]="required"
          [type]="type"
          (blur)="blur($event)"
        />
      }
    }
    @if (control.errors) {
      <mat-error>
        @for (error of control.errors | keyvalue; track error) {
          <span>
            {{
              error.key === 'error'
                ? error.value
                : (translationKey + '.' + error.key | transloco)
            }}
          </span>
        }
      </mat-error>
    }
    <span matSuffix>
      @if (type === 'date') {
        <button mat-icon-button type="button" (click)="openDatepicker()">
          <mat-icon>event</mat-icon>
        </button>
      }
      @if (suffixIcon) {
        <mat-icon>{{ suffixIcon }}</mat-icon>
      } @else {
        <ng-content select=".suffixTemplate"></ng-content>
      }
    </span>
  </mat-form-field>
} @else {
  <mat-checkbox color="primary" class="checkbox" [formControl]="control">{{
    placeholder
  }}</mat-checkbox>
}
