<button
  mat-icon-button
  class="sort-button"
  [class.non-active-sorting]="
    !currentKey?.endsWith(key) || currentKey?.endsWith('_' + key)
  "
  (click)="
    changeSorting.emit(
      currentKey?.endsWith(key) && !currentKey?.endsWith('_' + key)
        ? undefined
        : key
    )
  "
>
  <mat-icon>{{
    currentKey?.endsWith(key) &&
    !currentKey?.endsWith('_' + key) &&
    currentKey![0] === '-'
      ? 'arrow_downward'
      : 'arrow_upward'
  }}</mat-icon>
</button>
